import React, { useState, useRef, useEffect, useMemo } from "react";
import CurrentDateDisplay from "./components/currentDateDisplay";
import DateNavigator from "./components/dateNavigator";
import Appointment from "./components/appointment";
import ClinicianHeader from "./components/clinicianHeader";
import DurationSelector from "./components/durationSelector";
import ScheduleBody from "./components/scheduleBody";
import CurrentTime from "./components/currentTime";

const CalTest2 = ({ setModalOpen }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [showDurationSelector, setShowDurationSelector] = useState(false);
    const [selectorPosition, setSelectorPosition] = useState({ x: 0, y: 0 });
    const [selectedClinician, setSelectedClinician] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [clinicianWidths, setClinicianWidths] = useState([]);

    const [appointments, setAppointments] = useState([
        {
            clinicianId: 1,
            startTime: "9:00",
            endTime: "9:30",
            duration: 30,
            title: "Consultation",
            patientName: "John Doe",
        },
        {
            clinicianId: 1,
            startTime: "10:00",
            endTime: "10:30",
            duration: 30,
            title: "Follow-up",
            patientName: "Emily Rose",
        },
        {
            clinicianId: 2,
            startTime: "10:15",
            endTime: "10:45",
            duration: 30,
            title: "Routine Check",
            patientName: "Michael Johnson",
        },
        {
            clinicianId: 2,
            startTime: "11:00",
            endTime: "11:30",
            duration: 30,
            title: "Emergency",
            patientName: "Sarah Connor",
        },
        {
            clinicianId: 3,
            startTime: "11:15",
            endTime: "11:45",
            duration: 30,
            title: "Consultation",
            patientName: "James Bond",
        },
        {
            clinicianId: 3,
            startTime: "12:00",
            endTime: "12:30",
            duration: 30,
            title: "Vaccination",
            patientName: "Bruce Wayne",
        },
    ]);

    const clinicians = useMemo(() => [
        {
            id: 1,
            name: "Dr. Alice Smith",
            credentials: "MD",
            pictureUrl: "/path/to/image1.jpg",
            day: {
                workingHours: [9, 10, 11, 14, 15, 16],
            },
        },
        {
            id: 2,
            name: "Dr. Bob Johnson",
            credentials: "DO",
            pictureUrl: "/path/to/image2.jpg",
            day: {
                workingHours: [10, 11, 12, 13, 14, 15],
            },
        },
        {
            id: 3,
            name: "Dr. Carol Martinez",
            credentials: "PhD",
            pictureUrl: "/path/to/image3.jpg",
            day: {
                workingHours: [17, 18, 19 ,20],
            },
        },
        {
            id: 4,
            name: "Dr. Frank Smith",
            credentials: "PhD",
            pictureUrl: "/path/to/image4.jpg",
            day: {
                workingHours: [],
            },
        },
        {
            id: 5,
            name: "Dr. Frank Smith",
            credentials: "PhD",
            pictureUrl: "/path/to/image4.jpg",
            day: {
                workingHours: [],
            },
        },
        {
            id: 6,
            name: "Dr. Frank Smith",
            credentials: "PhD",
            pictureUrl: "/path/to/image4.jpg",
            day: {
                workingHours: [],
            },
        },
        {
            id: 7,
            name: "Dr. Frank Smith",
            credentials: "PhD",
            pictureUrl: "/path/to/image4.jpg",
            day: {
                workingHours: [],
            },
        },
    ], []);

    const clinicOpenTime = 8;
    const clinicCloseTime = 18;

    const hours = [];
    for (let hour = clinicOpenTime - 1; hour <= clinicCloseTime + 1; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            hours.push(`${hour}:${minute.toString().padStart(2, "0")}`);
        }
    }

    const cellRefs = useRef(
        hours.map(() => clinicians.map(() => React.createRef()))
    );

    const onPrevDay = () => {
        setCurrentDate(
            (prevDate) =>
                new Date(
                    prevDate.getFullYear(),
                    prevDate.getMonth(),
                    prevDate.getDate() - 1
                )
        );
    };

    const onToday = () => {
        setCurrentDate(new Date());
    };

    const onNextDay = () => {
        setCurrentDate(
            (prevDate) =>
                new Date(
                    prevDate.getFullYear(),
                    prevDate.getMonth(),
                    prevDate.getDate() + 1
                )
        );
    };

    const handleDragStart = (e, appointment) => {
        e.dataTransfer.setData(
            "application/react+json",
            JSON.stringify(appointment)
        );
    };

    const handleDrop = (e, clinicianId, timeSlot) => {
        e.preventDefault();
        const appointmentData = JSON.parse(
            e.dataTransfer.getData("application/react+json")
        );
        if (isAvailable(timeSlot, clinicianId)) {
            setAppointments(
                appointments.map((app) => {
                    if (
                        app.clinicianId === appointmentData.clinicianId &&
                        app.timeSlot === appointmentData.timeSlot
                    ) {
                        return { ...app, timeSlot }; // change only the timeSlot
                    }
                    return app;
                })
            );
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // Necessary to allow the drop
    };

    const scrollContainerRef = useRef(null);

    const handleRightClick = (
        e,
        clinicianId,
        timeSlot,
        hourIndex,
        clinicianIndex,
        cellRefs
    ) => {
        e.preventDefault();

        // Access the current element directly
        const cell = cellRefs.current[hourIndex][clinicianIndex].current;
        const rect = cell.getBoundingClientRect();

        setShowDurationSelector(true);
        setSelectorPosition({
            x: rect.left + window.scrollX,
            y: rect.top + window.scrollY,
        });

        setSelectedClinician(clinicianId);
        setSelectedTimeSlot(timeSlot);
    };

    const calculateEndTime = (startTime, duration) => {
        const [hour, minute] = startTime.split(":").map(Number);
        const time = new Date();
        time.setHours(hour, minute + duration, 0);
        return `${time.getHours()}:${time
            .getMinutes()
            .toString()
            .padStart(2, "0")}`;
    };

    const handleDurationSelect = (duration) => {
        setShowDurationSelector(false);
        const title = prompt("Enter the appointment title:");
        const patientName = prompt("Enter the patient's name:");
        if (title && patientName) {
            const startTime = selectedTimeSlot;
            const endTime = calculateEndTime(startTime, parseInt(duration));
            const newAppointment = {
                clinicianId: selectedClinician,
                startTime,
                endTime,
                duration,
                title,
                patientName,
            };
            console.log("New Appointment Created:", newAppointment);
            setAppointments([...appointments, newAppointment]);
        }
    };

    const handleCancel = () => {
        setShowDurationSelector(false);
    };

    const isAvailable = (timeSlot, clinicianId) => {
        const clinician = clinicians.find((c) => c.id === clinicianId);
        const hour = parseInt(timeSlot.split(":")[0], 10);
        const available = clinician.day.workingHours.includes(hour);

        return available;
    };

    const renderedAppointments = useMemo(() => {
        return appointments.map((appointment, index) => {
            const hourIndex = hours.findIndex(
                (hour) => hour === appointment.startTime
            );
            const clinicianIndex = clinicians.findIndex(
                (clin) => clin.id === appointment.clinicianId
            );
            const cell =
                cellRefs.current[hourIndex][clinicianIndex].current;
            if (!cell) return null; // Skip rendering if cell is not available
            const rect = cell.getBoundingClientRect();

            const style = {
                top: `${rect.top + window.scrollY}px`,
                left: `${rect.left + window.scrollX}px`,
                width: `${rect.width}px`,
                height: `${
                    rect.height * (appointment.duration / 15)
                }px`,
                position: "fixed",
                zIndex: 19,
            };

            return (
                <Appointment
                    key={index}
                    appointment={appointment}
                    handleDragStart={handleDragStart}
                    style={style}
                />
            );
        });
    }, [appointments, hours, clinicians]);

    return (
        <div className="flex flex-col h-full relative" ref={scrollContainerRef}>
            {showDurationSelector && (
                <DurationSelector
                    x={selectorPosition.x}
                    y={selectorPosition.y}
                    onSelect={handleDurationSelect}
                    onCancel={handleCancel}
                />
            )}
            <div className="bg-white z-30 shadow-lg sticky top-0 flex justify-between items-center px-4">
                <CurrentDateDisplay currentDate={currentDate} />
                <CurrentTime />
                <div className="flex items-center">
                    <DateNavigator
                        onPrevDay={onPrevDay}
                        onToday={onToday}
                        onNextDay={onNextDay}
                    />
                    <button
                        onClick={() => setModalOpen(false)}
                        className="ml-4"
                    >
                        X
                    </button>
                </div>
            </div>
            <div className="relative flex-grow overflow-auto">
                <table className="min-w-full">
                    <thead className="bg-white sticky top-0 z-20 shadow-lg">
                        <tr>
                            <th className="border px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20 bg-white">
                                Time
                            </th>
                            {clinicians.map((clinician) => (
                                <ClinicianHeader
                                    key={clinician.id}
                                    clinician={clinician}
                                />
                            ))}
                        </tr>
                    </thead>
                    <ScheduleBody
                        hours={hours}
                        clinicians={clinicians}
                        cellRefs={cellRefs}
                        isAvailable={isAvailable}
                        handleDragOver={handleDragOver}
                        handleDrop={handleDrop}
                        handleRightClick={handleRightClick}
                        setClinicianWidths={setClinicianWidths}
                    />
                </table>
                {renderedAppointments}
            </div>
        </div>
    );
};

export default CalTest2;