import React from "react";

export default function StripedTable(props) {
  const handleRowClick = (item) => {
    if (props.onRowClick) {
      props.onRowClick(item);
    }
  };

  const handleButtonClick = (item) => {
    if (props.onButtonClick) {
      props.onButtonClick(item);
    }
  };

  console.log(props.headings);
  console.log(props.data);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {props.title}
          </h1>
          <p className="mt-2 text-sm text-gray-700">{props.description}</p>
        </div>
        {props.showButton && (
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleButtonClick()}
            >
              {props.buttonText || "Add"}
            </button>
          </div>
        )}
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {props.headings.map((heading, index) => (
                    <th
                      key={index}
                      scope="col"
                      className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 ${
                        index === 0 ? "sm:pl-3" : ""
                      }`}
                    >
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {props.data.map((item, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                    onClick={props.rowClickable ? () => handleRowClick(item) : null}
                    style={{ cursor: props.rowClickable ? "pointer" : "default" }}
                  >
                    {props.headings.map((heading, subIndex) => (
                      <td
                        key={subIndex}
                        className={`whitespace-nowrap py-4 ${
                          subIndex === 0 ? "pl-4" : "px-3"
                        } py-2 text-sm ${
                          subIndex === 0
                            ? "font-medium text-gray-900"
                            : "text-gray-500"
                        }`}
                      >
                        {props.dataMapping[heading]
                          ? item[props.dataMapping[heading]]
                          : ""}
                      </td>
                    ))}
                    {props.showButton && (
                      <td>
                        {props.buttonRenderer
                          ? props.buttonRenderer(item)
                          : null}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
