// Appointment.js
import React from "react";

const Appointment = ({ appointment, handleDragStart, style }) => {
    return (
        <div
            className="absolute bg-blue-500 text-white rounded-md shadow-md flex items-center justify-center cursor-move"
            style={style}
            draggable="true"
            onDragStart={(e) => handleDragStart(e, appointment)}
        >
            {appointment.title} ({appointment.patientName})
        </div>
    );
};


export default Appointment;
