import React from "react";
import Event from "./event"; // Make sure to create and import this component

const dummyEvents = [
    {
        id: 1,
        title: "Morning Rounds",
        description: "Checking all assigned patients",
        startRow: 74, // Start time converted to grid row, assuming the grid starts at midnight
        duration: 12, // Duration in terms of grid rows
        time: "2022-01-22T06:00",
        displayTime: "6:00 AM"
    },
    {
        id: 2,
        title: "Team Meeting",
        description: "Weekly planning meeting",
        startRow: 92,
        duration: 30,
        time: "2022-01-22T07:30",
        displayTime: "7:30 AM"
    },
    {
        id: 3,
        title: "Surgery",
        description: "Knee replacement surgery",
        startRow: 134,
        duration: 18,
        time: "2022-01-22T11:00",
        displayTime: "11:00 AM"
    }
];


const ClinicianSchedule = ({ events = dummyEvents }) => {
    return (
        <>
            <div className="w-2 flex-none bg-white" />  // Adjusted width for the spacer
            <div className="flex flex-auto">
                {/* Horizontal lines replicated for each clinician schedule */}
                <div className="grid h-full w-full divide-y divide-gray-100" style={{ gridTemplateRows: "repeat(24, minmax(3.5rem, 1fr))" }}>
                    {/* Time markers, possibly empty divs just to extend the lines */}
                    {Array.from({ length: 24 }).map((_, index) => (
                        <div key={index}></div>
                    ))}
                </div>

                {/* Events dynamically rendered based on passed 'events' prop */}
                <ol className="absolute top-0 left-0 grid w-full grid-cols-1" style={{ gridTemplateRows: "repeat(1440, minmax(0, 1fr))" }}>
                    {events.map((event, index) => (
                        <Event key={index} event={event} />
                    ))}
                </ol>
            </div>
        </>
    );
};

export default ClinicianSchedule;
