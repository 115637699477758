import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
    "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
    // Add other headers as needed
  },
};

export const fetchClientsList = createAsyncThunk(
  "clients/fetchClientsList", 
  async (_, { getState }) => {

    const response = await axios.get(
      `https://portal.hearsay.ca/api/clients`,
      config
    );

    if (!response.status === 200) {
      throw new Error(response.data.message || "Could not fetch clients");
    }
    return response.data;
  }
);

export const updateClientClinician = createAsyncThunk(
    "clients/updateClinician",
    async ({ client, clinician }, { getState }) => {
        const data = { client, clinician };
        console.log(JSON.stringify(data));
        const response = await axios.post(
            `https://portal.hearsay.ca/api/clients/clinician`,
            data,
            config
          );
      
          if (!response.status === 200) {
            throw new Error(response.data.message || "Could not update clinician");
          }
          return response.data;
    }
)

export const updateClientSupervisor = createAsyncThunk(
    "clients/updateSupervisor",
    async ({ client, clinician }, { getState }) => {
        const data = { client, clinician };
        const response = await axios.post(
            `https://portal.hearsay.ca/api/clients/supervisor`,
            data,
            config
          );
      
          if (!response.status === 200) {
            throw new Error(response.data.message || "Could not update supervisor");
          }
          return response.data;
    }
)
