import React, { useState } from "react";
import MenuTabs from "../../Components/Tabs";
import Home from "../Home";
import Kpis from "../Kpis";
import ClientDropoff from "../ClientDropoff";
import QuickBooks from "../QuickBooks";
import Dashboard from "../Dashboard";
import QuickBooksCashFlow from "../../QuickBooks/QuickBooksCashFlow";
import QuickBooksSalesReport from "../../QuickBooks/QuickBooksSalesReport";
import QuickBooksInvoices from "../../QuickBooks/QuickBooksInvoices";
import UploadAppointments from "../../QuickBooks/QuickBooksAppointments";
import UploadClients from "../../QuickBooks/QuickBooksClients";
import WAAGReport from "../WAAG/WAAGReport";
import UploadSchedule from "../../QuickBooks/QuickBooksSchedule";

const tabs = [
    { name: "WAAG", href: "#", current: true },
    { name: "KPIs", href: "#", current: true },
    { name: "Dropoff", href: "#", current: false },
    { name: "Data", href: "#", current: false },
];

const Admin = () => {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const [selectedModule, setSelectedModule] = useState(tabs[0]);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleModuleClick = (moduleName) => {
        setSelectedModule(moduleName);
    }

    return (
        <>
            <div>
                <MenuTabs
                    tabs={tabs}
                    selectedTab={selectedTab}
                    onTabClick={handleTabClick}
                />
            </div>
            <div>
                {selectedTab.name === "WAAG" && <WAAGReport />}
                {selectedTab.name === "KPIs" && <Kpis />}
                {selectedTab.name === "Dropoff" && <ClientDropoff />}
                {selectedTab.name === "Data" && <QuickBooks onModuleClick={handleModuleClick} />}
                {selectedTab.name === "Data" && selectedModule === "Cash Flow Summary" && <QuickBooksCashFlow />}
                {selectedTab.name === "Data" && selectedModule === "Sales Report" && <QuickBooksSalesReport />}
                {selectedTab.name === "Data" && selectedModule === "Invoices" && <QuickBooksInvoices />}
                {selectedTab.name === "Data" && selectedModule === "Appointments" && <UploadAppointments />}
                {selectedTab.name === "Data" && selectedModule === "Schedule" && <UploadSchedule />}
                {selectedTab.name === "Data" && selectedModule === "Clients" && <UploadClients />}
            </div>
        </>
    );
};

export default Admin;
