import React, { useState } from "react";
import axios from "axios";

function UploadAppointments() {
    const [file, setFile] = useState(null);

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onUpload = async () => {
        const formData = new FormData();
        formData.append("csv", file);

        try {
            const response = await axios.post(
                "/api/appointments/analysis",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
                        "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
                    },
                }
            );
            console.log(response.data);
            alert('File upload completed successfully!');
        } catch (err) {
            console.error("Error uploading file:", err);
        }
    };

    return (
        <div className="container mx-auto mt-5">
            <h2 className="text-2xl font-semibold mb-4">
                Upload Appointment Analysis CSV
            </h2>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                    CSV File
                </label>
                <input
                    type="file"
                    className="mt-1 p-2 border rounded-lg"
                    onChange={onFileChange}
                />
            </div>
            <button
                className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
                onClick={onUpload}
            >
                Upload
            </button>
        </div>
    );
}

export default UploadAppointments;
