import { createSlice } from "@reduxjs/toolkit";
import { fetchClientsList } from "../Thunks/clientsThunk";

const clientsSlice = createSlice({

    name: "clients",
    initialState: {
        clientsList: [],
        status: "idle", // Consider renaming this to fetchStatus if you want
        isLoading: false, // From editActiveAppointmentSlice
        error: null,
    },
    extraReducers: (builder) => {
        builder
            // fetchClientsList thunks
            .addCase(fetchClientsList.pending, (state) => {
                state.status = "loading";
                state.isLoading = true; // Set isLoading to true
            })
            .addCase(fetchClientsList.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false; // Set isLoading back to false when successful
                state.clientsList = action.payload;
            })
            .addCase(fetchClientsList.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false; // Set isLoading back to false when failed
                state.error = action.error.message;
            });
    },
});

export default clientsSlice.reducer;
