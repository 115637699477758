import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PersonalInformation from "./PersonalInformation";
import TherapyAppointments from "./TherapyAppointments";
import ClientList from "./ClientList";
import { fetchAppointmentsByClinician } from "../../Store/Thunks/appointmentsThunk"; // Import the appropriate thunk

const ClinicianDetails = ({ clinician, onBackClick }) => {
    const [activeTab, setActiveTab] = useState("therapyAppointments");

    // Use useSelector to get appointments data from Redux store
    const appointmentData = useSelector(
        (state) => state.appointments.appointmentsList.appointments
    );
    const dispatch = useDispatch();

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        // Dispatch the thunk to fetch data when the component mounts
        dispatch(fetchAppointmentsByClinician(clinician._id));
    }, [dispatch, clinician]);

    return (
        <div className="container mx-auto p-4">
            <div className="flex items-center">
                {/* Use onBackClick to handle navigation back */}
                <button
                    className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded mr-3"
                    onClick={onBackClick}
                >
                    &lt; Back
                </button>
                <h2 className="text-2xl font-semibold">Clinician Details</h2>
            </div>

            <div className="bg-white rounded shadow p-4 mt-4">
                <h3 className="text-xl font-semibold">{clinician.name}</h3>
            </div>

            {/* Tab Navigation */}
            <ul className="flex space-x-4 mt-4">
                <li
                    className={`${
                        activeTab === "therapyAppointments"
                            ? "border-b-2 border-blue-500"
                            : ""
                    }`}
                    onClick={() => handleTabChange("therapyAppointments")}
                >
                    <a
                        className={`${
                            activeTab === "therapyAppointments"
                                ? "text-blue-500"
                                : "text-gray-600"
                        } cursor-pointer`}
                    >
                        Therapy Appointments
                    </a>
                </li>
                <li
                    className={`${
                        activeTab === "clientList"
                            ? "border-b-2 border-blue-500"
                            : ""
                    }`}
                    onClick={() => handleTabChange("clientList")}
                >
                    <a
                        className={`${
                            activeTab === "clientList"
                                ? "text-blue-500"
                                : "text-gray-600"
                        } cursor-pointer`}
                    >
                        Client List
                    </a>
                </li>
                <li
                    className={`${
                        activeTab === "personalInfo"
                            ? "border-b-2 border-blue-500"
                            : ""
                    }`}
                    onClick={() => handleTabChange("personalInfo")}
                >
                    <a
                        className={`${
                            activeTab === "personalInfo"
                                ? "text-blue-500"
                                : "text-gray-600"
                        } cursor-pointer`}
                    >
                        Personal Information
                    </a>
                </li>
        </ul>

            {/* Tab Content */}
            <div className="mt-4">
                {activeTab === "therapyAppointments" && (
                    <TherapyAppointments appointments={appointmentData} />
                )}
                {activeTab === "clientList" && (
                    <ClientList
                        appointments={appointmentData}
                        clinicianName={clinician.name}
                    />
                )}
                {activeTab === "personalInfo" && <PersonalInformation />}
            </div>
        </div>
    );
};

export default ClinicianDetails;
