import React from "react";
import { appointmentTypes } from "../Constants/appointmentTypes";
import StripedTable from "../Components/StripedTable";

const convertDate = (date) => {
    const appointmentDateParts = date.split("/"); // Assuming date is in the 4th column formatted as "MM/DD/YY"
    const appointmentDate = new Date(
        appointmentDateParts[2], // Year
        appointmentDateParts[1] - 1, // Month (subtracting 1 for zero-based indexing)
        appointmentDateParts[0] // Day
    );
    return appointmentDate;
};

const ClientList = ({ appointments }) => {
    const clientData = {};
    const today = new Date();
    const previousSunday = new Date();
    previousSunday.setDate(today.getDate() - ((today.getDay() + 7) % 7)); // Calculate the date of the previous Sunday

    // Process appointments data
    appointments.forEach((appointment) => {
        const clientId = appointment["Client ID"];
        let appointmentDate = convertDate(appointment["Date"]);
        const appointmentType = appointment["Type"];
        const clientName = appointment["Client"];
        const foundType = appointmentTypes.find(
            (type) => type.type === appointmentType
        );
        if (!foundType || foundType.category !== "Speech Therapy") {
            return;
        }
        if (!clientData[clientId]) {
            clientData[clientId] = {
                clientId,
                clientName,
                lastAppointmentDate: appointmentDate,
                lastAppointmentDateString: appointmentDate.toDateString(),
                appointmentType,
                totalAppointments: 1,
            };
        } else if (appointmentDate > clientData[clientId].lastAppointmentDate) {
            clientData[clientId] = {
                ...clientData[clientId],
                lastAppointmentDate: appointmentDate,
                lastAppointmentDateString: appointmentDate.toDateString(),
                appointmentType,
            };
            clientData[clientId].totalAppointments++;
        }
    });

    // Convert clientData object to an array and sort by last appointment date (oldest to newest)
    const clientList = Object.values(clientData).sort(
        (a, b) => a.lastAppointmentDate - b.lastAppointmentDate
    );

    // Filter clients whose last appointment was between 60 and 67 days prior to the previous Sunday
    const filteredClients = clientList.filter((client) => {
        const daysSinceLastAppointment = Math.floor(
            (previousSunday.getTime() - client.lastAppointmentDate.getTime()) /
                (1000 * 60 * 60 * 24)
        );
        return daysSinceLastAppointment >= 60 && daysSinceLastAppointment <= 67;
    });

    // Count the number of active clients (clients with appointments in the last 59 days and category "Speech Therapy")
    const activeClientsCount = clientList.filter(
        (client) =>
            appointmentTypes.find(
                (type) =>
                    type.category === "Speech Therapy" &&
                    type.type === client.appointmentType
            ) &&
            Math.floor(
                (today.setHours(0, 0, 0, 0) -
                    client.lastAppointmentDate.setHours(0, 0, 0, 0)) /
                    (1000 * 60 * 60 * 24)
            ) <= 59
    ).length;

    // Count the number of active clients within the last 30 days (with category "Speech Therapy")
    const activeClients30DaysCount = clientList.filter(
        (client) =>
            appointmentTypes.find(
                (type) =>
                    type.category === "Speech Therapy" &&
                    type.type === client.appointmentType
            ) &&
            Math.floor(
                (today.setHours(0, 0, 0, 0) -
                    client.lastAppointmentDate.setHours(0, 0, 0, 0)) /
                    (1000 * 60 * 60 * 24)
            ) <= 30
    ).length;

    return (
        <div>
            <StripedTable
                title="Client Drop Off"
                description="A table of client's last appointment"
                headings={["Client Id", "Client Name", "Last Appointment Date", "Appointment Type", "Total Appointments"]}
                data={clientList}
                showButton={false} // Set to false if you don't want the button
                dataMapping={{
                    "Client Id": "clientId", 
                    "Client Name": "clientName", 
                    "Last Appointment Date": "lastAppointmentDateString", 
                    "Appointment Type": "appointmentType", 
                    "Total Appointments": "totalAppointments", 
                }}
            />
            {/* <h2 className="text-2xl font-semibold mb-4">Client List</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full table-auto border-collapse border border-gray-300">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Client ID</th>
                            <th className="px-4 py-2">Client Name</th>
                            <th className="px-4 py-2">Last Appointment Date</th>
                            <th className="px-4 py-2">Appointment Type</th>
                            <th className="px-4 py-2">Total Appointments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientList.map(
                            ({
                                clientId,
                                clientName,
                                lastAppointmentDate,
                                appointmentType,
                                totalAppointments,
                            }) => (
                                <tr key={clientId}>
                                    <td className="px-4 py-2">{clientId}</td>
                                    <td className="px-4 py-2">{clientName}</td>
                                    <td className="px-4 py-2">
                                        {lastAppointmentDate.toDateString()}
                                    </td>
                                    <td className="px-4 py-2">
                                        {appointmentType}
                                    </td>
                                    <td className="px-4 py-2">
                                        {totalAppointments}
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div> */}

            <h2 className="mt-4 mb-4 text-2xl font-semibold">
                Filtered Clients
            </h2>
            <div className="overflow-x-auto">
                <table className="min-w-full table-auto border-collapse border border-gray-300">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="px-4 py-2">Client ID</th>
                            <th className="px-4 py-2">Client Name</th>
                            <th className="px-4 py-2">Last Appointment Date</th>
                            <th className="px-4 py-2">Appointment Type</th>
                            <th className="px-4 py-2">Total Appointments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredClients.map(
                            ({
                                clientId,
                                clientName,
                                lastAppointmentDate,
                                appointmentType,
                                totalAppointments,
                            }) => (
                                <tr key={clientId}>
                                    <td className="px-4 py-2">{clientId}</td>
                                    <td className="px-4 py-2">{clientName}</td>
                                    <td className="px-4 py-2">
                                        {lastAppointmentDate.toDateString()}
                                    </td>
                                    <td className="px-4 py-2">
                                        {appointmentType}
                                    </td>
                                    <td className="px-4 py-2">
                                        {totalAppointments}
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>

            <p className="mt-4">
                Number of Active Clients (within 59 days and category "Speech
                Therapy"): {activeClientsCount}
            </p>
            <p>
                Number of Active Clients (within 30 days and category "Speech
                Therapy"): {activeClients30DaysCount}
            </p>
        </div>
    );
};

export default ClientList;
