import { createSlice } from "@reduxjs/toolkit";
import { fetchInvoices, fetchWaagTotals } from "../Thunks/invoicesThunk";


const invoiceSlice = createSlice({

    name: "clients",
    initialState: {
        invoicesList: [],
        waagTotals: [],
        status: "idle", // Consider renaming this to fetchStatus if you want
        isLoading: false, // From editActiveAppointmentSlice
        error: null,
    },
    extraReducers: (builder) => {
        builder
            // fetchInvoicesList thunks
            .addCase(fetchInvoices.pending, (state) => {
                state.status = "loading";
                state.isLoading = true; // Set isLoading to true
            })
            .addCase(fetchInvoices.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false; // Set isLoading back to false when successful
                state.invoicesList = action.payload;
            })
            .addCase(fetchInvoices.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false; // Set isLoading back to false when failed
                state.error = action.error.message;
            })
            // fetchClientsList thunks
            .addCase(fetchWaagTotals.pending, (state) => {
                state.status = "loading";
                state.isLoading = true; // Set isLoading to true
            })
            .addCase(fetchWaagTotals.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false; // Set isLoading back to false when successful
                state.waagTotals = action.payload;
            })
            .addCase(fetchWaagTotals.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false; // Set isLoading back to false when failed
                state.error = action.error.message;
            });
    },
});

export default invoiceSlice.reducer;
