import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import useIsSignedIn from "../Auth/UseIsSignedIn";
import Home from "../Pages/Home";
import Clinicians from "../Pages/Clinicians/Clinicians";
import CombinedClient from "../Pages/Clients/CombinedClient";
import Admin from "../Pages/Admin/Admin";
import { Login } from "@microsoft/mgt-react";
import "./Menu2.css";
import Reports from "../Pages/Reports/Reports";
import Scheduler from "../Pages/Schedule/Scheduler";
import CalTest from "../Pages/Schedule/CalTest";
import FullScreenSchedule from "../Pages/Schedule/FullScreenSchedule";
import CalendarIcon from "./CalendarIcon";
import CalTest2 from "../Pages/Schedule/CalTest2";

const user = {
    name: "Tom Cook",
    email: "tom@example.com",
    imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Sign out", href: "#" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Menu2() {
    const location = useLocation();
    const [isSignedIn] = useIsSignedIn();
    const [selectedMenu, setSelectedMenu] = useState("Dashboard");
    const [isModalOpen, setModalOpen] = useState(false);

    const [navigation, setNavigation] = useState([
        { name: "Dashboard", href: "/", current: true },
        { name: "Clinicians", href: "/clinicians", current: false },
        { name: "Clients", href: "/clients", current: false },
        { name: "Admin", href: "/admin", current: false },
        { name: "Reports", href: "/reports", current: false },
        { name: "Scheduler", href: "/scheduler", current: false },
    ]);

    const loginStyle = {
        color: "white",
    };

    const handleMenuItemClick = (item) => {
        const updatedNavigation = navigation.map((navItem) => ({
            ...navItem,
            current: navItem.name === item.name,
        }));
        setSelectedMenu(item.name);
        setNavigation(updatedNavigation);
    };

    return (
        <>
            <div className="min-h-screen flex flex-col">
                <Disclosure
                    as="nav"
                    className="bg-indigo-600 fixed w-full top-0 z-40"
                >
                    {({ open }) => (
                        <>
                            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                <div className="flex h-16 items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-8 w-8"
                                                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=300"
                                                alt="Your Company"
                                            />
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4">
                                                {navigation.map((item) => (
                                                    <button
                                                        key={item.name}
                                                        onClick={() =>
                                                            handleMenuItemClick(
                                                                item
                                                            )
                                                        }
                                                        className={classNames(
                                                            item.current
                                                                ? "bg-indigo-700 text-white"
                                                                : "text-white hover:bg-indigo-500 hover:bg-opacity-75",
                                                            "rounded-md px-3 py-2 text-sm font-medium"
                                                        )}
                                                        aria-current={
                                                            item.current
                                                                ? "page"
                                                                : undefined
                                                        }
                                                    >
                                                        {item.name}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            <button
                                                type="button"
                                                className="relative rounded-full bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                            >
                                                <span className="absolute -inset-1.5" />
                                                <span className="sr-only">
                                                    View notifications
                                                </span>
                                                <BellIcon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                            <Login style={loginStyle} />

                                            {/* Profile dropdown
                                            <Menu
                                                as="div"
                                                className="relative ml-3"
                                            >
                                                <div>
                                                    <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-indigo-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                                        <span className="absolute -inset-1.5" />
                                                        <span className="sr-only">
                                                            Open user menu
                                                        </span>
                                                        <img
                                                            className="h-8 w-8 rounded-full"
                                                            src={user.imageUrl}
                                                            alt=""
                                                        />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {userNavigation.map(
                                                            (item) => (
                                                                <Menu.Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {({
                                                                        active,
                                                                    }) => (
                                                                        <a
                                                                            href={
                                                                                item.href
                                                                            }
                                                                            className={classNames(
                                                                                active
                                                                                    ? "bg-gray-100"
                                                                                    : "",
                                                                                "block px-4 py-2 text-sm text-gray-700"
                                                                            )}
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </a>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        )}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu> */}
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                            <span className="absolute -inset-0.5" />
                                            <span className="sr-only">
                                                Open main menu
                                            </span>
                                            {open ? (
                                                <XMarkIcon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <Bars3Icon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="md:hidden">
                                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                    {navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.href}
                                            className={classNames(
                                                item.current
                                                    ? "bg-indigo-700 text-white"
                                                    : "text-white hover:bg-indigo-500 hover:bg-opacity-75",
                                                "block rounded-md px-3 py-2 text-base font-medium"
                                            )}
                                            aria-current={
                                                item.current
                                                    ? "page"
                                                    : undefined
                                            }
                                            onClick={() =>
                                                handleMenuItemClick(item)
                                            }
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>
                                <div className="border-t border-indigo-700 pb-3 pt-4">
                                    <div className="flex items-center px-5">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-10 w-10 rounded-full"
                                                src={user.imageUrl}
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-3">
                                            <div className="text-base font-medium text-white">
                                                {user.name}
                                            </div>
                                            <div className="text-sm font-medium text-indigo-300">
                                                {user.email}
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className="relative ml-auto flex-shrink-0 rounded-full border-2 border-transparent bg-indigo-600 p-1 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                                        >
                                            <span className="absolute -inset-1.5" />
                                            <span className="sr-only">
                                                View notifications
                                            </span>
                                            <BellIcon
                                                className="h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                    <div className="mt-3 space-y-1 px-2">
                                        {userNavigation.map((item) => (
                                            <Disclosure.Button
                                                key={item.name}
                                                as="a"
                                                href={item.href}
                                                className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75"
                                            >
                                                {item.name}
                                            </Disclosure.Button>
                                        ))}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                <header className="bg-white shadow fixed w-full top-16 z-30">
                    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                            {selectedMenu}
                        </h1>
                    </div>
                </header>
                <main className="flex-1 overflow-y-auto p-6 mt-16">
                    <div className="mx-auto py-6 sm:px-6 lg:px-8 pt-16">
                        {isSignedIn && selectedMenu === "Dashboard" && <Home />}
                        {isSignedIn && selectedMenu === "Clinicians" && (
                            <Clinicians />
                        )}
                        {isSignedIn && selectedMenu === "Clients" && (
                            <CombinedClient />
                        )}
                        {isSignedIn && selectedMenu === "Admin" && <Admin />}
                        {isSignedIn && selectedMenu === "Reports" && (
                            <Reports />
                        )}
                        {isSignedIn && selectedMenu === "Scheduler" && (
                            <CalTest />
                        )}
                    </div>
                </main>
                <div>
                    <FullScreenSchedule
                        isOpen={isModalOpen}
                        onClose={() => setModalOpen(false)}
                    >
                        <CalTest2 setModalOpen={setModalOpen}/>
                    </FullScreenSchedule>
                    <CalendarIcon onOpen={() => setModalOpen(true)} />
                </div>
            </div>
        </>
    );
}
