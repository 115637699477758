// UploadClients.js

import React, { useState } from 'react';
import axios from 'axios';

function UploadClients() {
    const [file, setFile] = useState(null);

    const onFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const onUpload = async () => {
        const formData = new FormData();
        formData.append('csv', file);

        try {
            const response = await axios.post('/api/clients/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
                    "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
                }
            });
            console.log(response.data);
        } catch (err) {
            console.error("Error uploading file:", err);
        }
    };

    return (
        <div className="container mt-5">
            <h2>Upload Client Data CSV</h2>
            <div className="mb-3">
                <label className="form-label">CSV File</label>
                <input type="file" className="form-control" onChange={onFileChange} />
            </div>
            <button className="btn btn-primary" onClick={onUpload}>Upload</button>
        </div>
    );
}

export default UploadClients;
