import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useIsSignedIn from "./UseIsSignedIn";

const ProtectedRoute = (props) => {
    const isSignedIn = useIsSignedIn();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        if (!isSignedIn) {
            setIsLoggedIn(false);
            return navigate("/login");
        }
        setIsLoggedIn(true);
    };

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    return (
        <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>
    );
};
export default ProtectedRoute;
