import React from "react";
import { useLocation } from "react-router-dom";
import QuickBooksConnectButton from "../QuickBooks/QuickBooksConnectButton";

function QuickBooksSuccess({ onModuleClick }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const status = params.get("status");

  const handleModuleClick = (moduleName) => {
    onModuleClick(moduleName); // Notify the parent component of the clicked module
  };

  return (
    <div className="mt-4">
      <div className="flex justify-center gap-2">
        <button
          onClick={() => handleModuleClick("Cash Flow Summary")}
          className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
        >
          Cash Flow Summary
        </button>
        <button
          onClick={() => handleModuleClick("Sales Report")}
          className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
        >
          Sales Report
        </button>
        <button
          onClick={() => handleModuleClick("Invoices")}
          className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
        >
          Invoices
        </button>
        <button
          onClick={() => handleModuleClick("Appointments")}
          className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
        >
          Appointments
        </button>
        <button
          onClick={() => handleModuleClick("Schedule")}
          className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
        >
          Schedule
        </button>
        <button
          onClick={() => handleModuleClick("Clients")}
          className="rounded bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50"
        >
          Clients
        </button>
      </div>
      {status === "connected" ? (
        <>
          <div className="alert alert-success mt-4" role="alert">
            Successfully connected to QuickBooks!
          </div>
        </>
      ) : (
        <div className="alert alert-danger mt-4" role="alert">
          Failed to connect to QuickBooks.
        </div>
      )}
    </div>
  );
}

function QuickBooks({ onModuleClick }) {
  const handleModuleClick = (moduleName) => {
    onModuleClick(moduleName); // Notify the parent component of the clicked module
  };

  return (
    <div className="container mx-auto mt-5 p-4 bg-light rounded shadow-sm text-center">
      <h1 className="mb-4 text-2xl font-semibold">QuickBooks Integration</h1>
      <QuickBooksConnectButton />
      <QuickBooksSuccess onModuleClick={handleModuleClick} />
    </div>
  );
}

export default QuickBooks;
