import React, { useEffect, useState } from "react";
import { Login } from "@microsoft/mgt-react";
import useIsSignedIn from "../../Auth/UseIsSignedIn";
import { useNavigate } from "react-router-dom";


const LoginUser = () => {
    const isSignedIn = useIsSignedIn();
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        if (isSignedIn) {
            setIsLoggedIn(true);
            return navigate("/");
        }
        setIsLoggedIn(false);
    };

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <Login />
    </div>
  );
};

export default LoginUser;
