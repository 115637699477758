import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCliniciansList } from "../../Store/store";
import ClinicianDetails from "./ClinicianDetails"; // Import the ClinicianDetails component
import StripedTable from "../../Components/StripedTable";

const Clinicians = () => {
    const dispatch = useDispatch();
    const clinicians = useSelector((state) => state.clinicians.cliniciansList);
    const [filteredClinicians, setFilteredClinicians] = useState(clinicians);
    const [selectedClinician, setSelectedClinician] = useState(null);

    useEffect(() => {
        dispatch(fetchCliniciansList());
    }, [dispatch]);

    const handleClinicianClick = (clinician) => {
        setSelectedClinician(clinician);
    };

    const handleBackClick = () => {
        setSelectedClinician(null); // Clear the selected clinician
    };

    useEffect(() => {
        // Filter out empty or blank records
        const filteredClinicians = clinicians.filter((clinician) => clinician.name);
    
        // Sort the filteredClinicians array
        const sortedClinicians = [...filteredClinicians].sort((a, b) => {
            const nameA = (a.name || "").toLowerCase();
            const nameB = (b.name || "").toLowerCase();
    
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    
        // Update the state with the sorted and filtered data
        setFilteredClinicians(sortedClinicians);
    }, [clinicians]);

    return (
        <div className="container">
            <h2>Clinicians</h2>
            {selectedClinician === null ? (
                <div className="row">
                    <div className="col-md-6">
                    <StripedTable
                        title="Clinician List"
                        description="A table of clinicians"
                        headings={["Clinician Name"]}
                        data={filteredClinicians}
                        rowClickable={true}
                        onRowClick={(item) => handleClinicianClick(item)}
                        showButton={false} // Set to false if you don't want the button
                        dataMapping={{
                            "Clinician Name": "name", // Map the heading to the data property
                        }}
                    />
                        {/* <h3>Clinician List</h3>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(clinicians) ? (
                                    clinicians.map((clinician) => (
                                        <tr
                                            key={clinician._id}
                                            onClick={() => handleClinicianClick(clinician)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <td>{clinician.name}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="2">Loading clinicians...</td>
                                    </tr>
                                )}
                            </tbody>
                        </table> */}
                    </div>
                </div>
            ) : (
                <ClinicianDetails clinician={selectedClinician} onBackClick={handleBackClick} />
            )}
        </div>
    );
};

export default Clinicians;
