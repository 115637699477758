import React from "react";

const CurrentDateDisplay = ({ currentDate }) => {
    // Destructuring currentDate from props
    // Ensure currentDate is a Date object
    const displayDate = currentDate instanceof Date ? currentDate : new Date();
    const dateOptions = { year: "numeric", month: "long", day: "numeric" };
    const shortDateOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
    };

    // Full date in "January 22, 2022" format
    const fullDate = displayDate.toLocaleDateString("en-US", dateOptions);

    // Short date in "Jan 22, 2022" format
    const shortDate = displayDate.toLocaleDateString("en-US", shortDateOptions);

    // Get day of the week, e.g., "Saturday"
    const dayOfWeek = displayDate.toLocaleDateString("en-US", {
        weekday: "long",
    });

    return (
        <div>
            <h1 className="text-base font-semibold leading-6 text-gray-900">
                <time
                    dateTime={displayDate.toISOString()}
                    className="sm:hidden"
                >
                    {shortDate}
                </time>
                <time
                    dateTime={displayDate.toISOString()}
                    className="hidden sm:inline"
                >
                    {fullDate}
                </time>
            </h1>
            <p className="mt-1 text-sm text-gray-500">{dayOfWeek}</p>
        </div>
    );
};

export default CurrentDateDisplay;
