import React, { useState, useEffect } from "react";
import axios from "axios";
import { appointmentTypes } from "../Constants/appointmentTypes";
import ClientSummaryTable from "../Tables/ClientSummaryTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Kpis = () => {
    const [loading, setLoading] = useState(true); // Track loading state
    const [appointments, setAppointments] = useState([]);
    const [categorySummary, setCategorySummary] = useState({});
    const [appointmentsData, setAppointmentsData] = useState([]);
    const [asOfDate, setAsOfDate] = useState(new Date()); // Initialize asOfDate with the current date

    const handleDateChange = (date) => {
        setAsOfDate(date);
    };

    const config = {
        headers: {
            "Content-Type": "application/json",
            "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
            "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
            // Add other headers as needed
        },
    };

    useEffect(() => {
        const fetchAppointmentsData = async () => {
            try {
                const response = await axios.get(
                    "https://portal.hearsay.ca/api/schedule",
                    config
                );
                const appointmentsArray = response.data;
    
                // Process the data (similar to your original code)
                const categorySummary = {};
                appointmentTypes.forEach(({ category }) => {
                    categorySummary[category] = 0;
                });
    
                const filteredAppointments = appointmentsArray.filter(
                    (appointment) => {
                        const appointmentType = appointment["Type"]; // Assuming the appointment type is in the "Type" property
                        const foundType = appointmentTypes.find(
                            ({ type }) => type === appointmentType
                        );
    
                        if (foundType && foundType.category !== "Ignore") {
                            const category = foundType.category;
                            const duration = parseInt(
                                appointment["Actual duration"],
                                10
                            );
                            categorySummary[category] += duration;
    
                            return true;
                        } else {
                            if (foundType && foundType.category === "Ignore") {
                                // Appointment type marked as "Ignore"
                            } else {
                                console.log(
                                    `Appointment type "${appointmentType}" not found.`
                                );
                            }
                            return false;
                        }
                    }
                );
    
                setAppointments(filteredAppointments);
                setCategorySummary(categorySummary);
                setAppointmentsData(filteredAppointments);
                setLoading(false); // Set loading to false when data is loaded
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false); // Set loading to false on error
            }
        };
    

        fetchAppointmentsData();
    }, []);


    return (
        <>
            <div className="d-flex justify-content-end pr-4">
                {" "}
                {/* Align to the right with 4rem right padding */}
                <div className="mb-3">
                    <label htmlFor="asOfDate" className="form-label">
                        Select Date:
                    </label>
                    <DatePicker
                        id="asOfDate"
                        selected={asOfDate}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        popperPlacement="top-end" // Position the date picker to the top right
                    />
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {loading ? (
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon="spinner"
                                    size="6x"
                                    spin
                                />
                            </div>
                        ) : (
                            <div>
                                <p>
                                    Number of Appointments:{" "}
                                    {appointments.length}
                                </p>
                                <ClientSummaryTable
                                    appointments={appointments}
                                    categorySummary={categorySummary}
                                    asOfDate={asOfDate}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Kpis;
