import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./Store/store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { BrowserRouter } from "react-router-dom";

Providers.globalProvider = new Msal2Provider({
    clientId: "604a6092-1806-4e06-a1b8-3ab673a14515",
    authority:
        "https://login.microsoftonline.com/93be9f90-f7e5-4653-a9e4-f076f108c9cf",
    scopes: [
        "calendars.read",
        "user.read",
        "openid",
        "profile",
        "people.read",
        "user.readbasic.all",
    ],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
