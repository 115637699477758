import React, { useState } from "react";

const PayPeriodDropdown = ({ onSelectPeriod }) => {
    const payPeriods = [
        {
            id: "2023-1",
            title: "Pay Period 1",
            startDate: new Date("2022-12-09"),
            endDate: new Date("2022-12-22"),
            paymentDate: new Date("2023-01-06"),
        },
        {
            id: "2023-2",
            title: "Pay Period 2",
            startDate: new Date("2022-12-23"),
            endDate: new Date("2023-01-05"),
            paymentDate: new Date("2023-01-20"),
        },
        {
            id: "2023-3",
            title: "Pay Period 3",
            startDate: new Date("2023-01-06"),
            endDate: new Date("2023-01-19"),
            paymentDate: new Date("2023-02-03"),
        },
        {
            id: "2023-4",
            title: "Pay Period 4",
            startDate: new Date("2023-01-20"),
            endDate: new Date("2023-02-02"),
            paymentDate: new Date("2023-02-17"),
        },
        {
            id: "2023-5",
            title: "Pay Period 5",
            startDate: new Date("2023-02-03"),
            endDate: new Date("2023-02-16"),
            paymentDate: new Date("2023-03-03"),
        },
        {
            id: "2023-6",
            title: "Pay Period 6",
            startDate: new Date("2023-02-17"),
            endDate: new Date("2023-03-02"),
            paymentDate: new Date("2023-03-17"),
        },
        {
            id: "2023-7",
            title: "Pay Period 7",
            startDate: new Date("2023-03-03"),
            endDate: new Date("2023-03-16"),
            paymentDate: new Date("2023-03-31"),
        },
        {
            id: "2023-8",
            title: "Pay Period 8",
            startDate: new Date("2023-03-17"),
            endDate: new Date("2023-03-30"),
            paymentDate: new Date("2023-04-14"),
        },
        {
            id: "2023-9",
            title: "Pay Period 9",
            startDate: new Date("2023-03-31"),
            endDate: new Date("2023-04-13"),
            paymentDate: new Date("2023-04-28"),
        },
        {
            id: "2023-10",
            title: "Pay Period 10",
            startDate: new Date("2023-04-14"),
            endDate: new Date("2023-04-27"),
            paymentDate: new Date("2023-05-12"),
        },
        {
            id: "2023-11",
            title: "Pay Period 11",
            startDate: new Date("2023-04-28"),
            endDate: new Date("2023-05-11"),
            paymentDate: new Date("2023-05-26"),
        },
        {
            id: "2023-12",
            title: "Pay Period 12",
            startDate: new Date("2023-05-12"),
            endDate: new Date("2023-05-25"),
            paymentDate: new Date("2023-06-09"),
        },
        {
            id: "2023-13",
            title: "Pay Period 13",
            startDate: new Date("2023-05-26"),
            endDate: new Date("2023-06-08"),
            paymentDate: new Date("2023-06-23"),
        },
        {
            id: "2023-14",
            title: "Pay Period 14",
            startDate: new Date("2023-06-09"),
            endDate: new Date("2023-06-22"),
            paymentDate: new Date("2023-07-07"),
        },
        {
            id: "2023-15",
            title: "Pay Period 15",
            startDate: new Date("2023-06-23"),
            endDate: new Date("2023-07-06"),
            paymentDate: new Date("2023-07-21"),
        },
        {
            id: "2023-16",
            title: "Pay Period 16",
            startDate: new Date("2023-07-07"),
            endDate: new Date("2023-07-20"),
            paymentDate: new Date("2023-08-04"),
        },
        {
            id: "2023-17",
            title: "Pay Period 17",
            startDate: new Date("2023-07-21"),
            endDate: new Date("2023-08-03"),
            paymentDate: new Date("2023-08-18"),
        },
        {
            id: "2023-18",
            title: "Pay Period 18",
            startDate: new Date("2023-08-04"),
            endDate: new Date("2023-08-17"),
            paymentDate: new Date("2023-09-01"),
        },
        {
            id: "2023-19",
            title: "Pay Period 19",
            startDate: new Date("2023-08-18"),
            endDate: new Date("2023-08-31"),
            paymentDate: new Date("2023-09-15"),
        },
        {
            id: "2023-20",
            title: "Pay Period 20",
            startDate: new Date("2023-09-01"),
            endDate: new Date("2023-09-14"),
            paymentDate: new Date("2023-09-29"),
        },
        {
            id: "2023-21",
            title: "Pay Period 21",
            startDate: new Date("2023-09-15"),
            endDate: new Date("2023-09-28"),
            paymentDate: new Date("2023-10-13"),
        },
        {
            id: "2023-22",
            title: "Pay Period 22",
            startDate: new Date("2023-09-29"),
            endDate: new Date("2023-10-12"),
            paymentDate: new Date("2023-10-27"),
        },
        {
            id: "2023-23",
            title: "Pay Period 23",
            startDate: new Date("2023-10-13"),
            endDate: new Date("2023-10-26"),
            paymentDate: new Date("2023-11-10"),
        },
        {
            id: "2023-24",
            title: "Pay Period 24",
            startDate: new Date("2023-10-27"),
            endDate: new Date("2023-11-09"),
            paymentDate: new Date("2023-11-24"),
        },
        {
            id: "2023-25",
            title: "Pay Period 25",
            startDate: new Date("2023-11-10"),
            endDate: new Date("2023-11-23"),
            paymentDate: new Date("2023-12-07"),
        },
    ];

    const [selectedPeriod, setSelectedPeriod] = useState("");

    const handlePeriodChange = (event) => {
        const selectedValue = event.target.value;
        const selectedPayPeriod = payPeriods.find(
            (period) => period.id === selectedValue
        );
        setSelectedPeriod(selectedPayPeriod);
        onSelectPeriod(selectedPayPeriod);
    };

    return (
        <div className="my-4">
            <label htmlFor="payPeriod" className="mr-2 font-semibold">
                Select Pay Period:
            </label>
            <select
                id="payPeriod"
                value={selectedPeriod ? selectedPeriod.id : ""}
                onChange={handlePeriodChange}
                className="border border-gray-300 rounded-md p-1 h-12"
            >
                <option value="">Select a Pay Period</option>
                {payPeriods.map((period) => (
                    <option key={period.id} value={period.id}>
                        {period.title}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default PayPeriodDropdown;
