import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchAppointmentsByDateRange,
    fetchCliniciansList,
} from "../../../Store/store";
import PayPeriodDropdown from "./PayPeriodDropDown";
import StripedTable from "../../../Components/StripedTable";
import SoapNote from "../../../Components/SoapNote";
import SoapNoteButton from "../../../Components/SoapNoteButton";

// Define the PayrollReport component
const PayrollReport = () => {
    const dispatch = useDispatch();
    const clinicians = useSelector((state) => state.clinicians.cliniciansList);
    const appointments = useSelector(
        (state) => state.appointments.appointmentsList.appointments
    );

    // Use local state to keep track of the selected clinician
    const [selectedClinician, setSelectedClinician] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [filteredAppointments, setFilteredAppointments] = useState(null);
    const [isSoapNoteModalOpen, setSoapNoteModalOpen] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [isViewMode, setViewMode] = useState(false);

    useEffect(() => {
        // Fetch clinicians data when the component mounts
        dispatch(fetchCliniciansList());
    }, [dispatch]);

    // Function to handle selecting a clinician
    const handleClinicianSelect = (clinician) => {
        console.log(clinician);
        setSelectedClinician(clinician);

        // Filter appointments by clinician and update the state with filtered appointments
        const filteredAppointments = appointments.filter(
            (appointment) => appointment.Provider === clinician.name
        );
        const sortedAppointments = filteredAppointments.slice().sort((a, b) => {
            const dateA = convertDateToSortableFormat(a.Date);
            const dateB = convertDateToSortableFormat(b.Date);
            return dateB.localeCompare(dateA);
        });
        setFilteredAppointments(sortedAppointments);
    };


    const handlePayPeriodSelect = (selectedPeriod) => {
        setSelectedPeriod(selectedPeriod);

        // Extract start and end dates from the selected pay period
        const { startDate, endDate } = selectedPeriod;

        // Dispatch the fetchAppointmentsByDateRange thunk with the date range parameters
        dispatch(fetchAppointmentsByDateRange({ startDate, endDate }));

        console.log("Selected Pay Period:", selectedPeriod);
    };

    const openSoapNoteModal = (item, viewMode) => {
        setSelectedAppointment(item);
        setViewMode(viewMode);
        setSoapNoteModalOpen(true);
    };

    const closeSoapNoteModal = () => {
        setSelectedAppointment(null);
        setViewMode(false);
        setSoapNoteModalOpen(false);
    };

    const handleViewSoapClick = (item) => {
        // Open the SOAP note modal in view mode
        openSoapNoteModal(item, true);
    };

    return (
        <div>
            <PayPeriodDropdown onSelectPeriod={handlePayPeriodSelect} />

            {selectedPeriod ? ( // Check if a pay period is selected
                <div className="flex">
                    {/* Left-hand tab accordion */}
                    <div className="w-1/4 bg-gray-200 p-4">
                        <h2 className="text-xl font-semibold mb-4">
                            Clinicians
                        </h2>
                        <ul>
                            {clinicians.map((clinician) => (
                                <li
                                    key={clinician.id}
                                    className={`cursor-pointer ${
                                        selectedClinician === clinician
                                            ? "bg-blue-500 text-white"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        handleClinicianSelect(clinician)
                                    }
                                >
                                    {clinician.name}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Right-hand content */}
                    <div className="w-3/4 p-4">
                        {selectedClinician ? (
                            <div>
                                {/* Display payroll report for the selected clinician */}
                                <h2 className="text-xl font-semibold mb-4">
                                    Payroll Report for {selectedClinician.name}
                                </h2>
                                <StripedTable
                                    title="Appointment List"
                                    description="A table of client appointments"
                                    headings={[
                                        "Date",
                                        "Client",
                                        "Type",
                                        "Clinician",
                                        "Soap Notes",
                                    ]}
                                    data={filteredAppointments}
                                    showButton={false} // Set to false if you don't want the button
                                    dataMapping={{
                                        Date: "Date", // Map the heading to the data property
                                        Client: "Client", // Map the heading to the data property
                                        Type: "Type", // Map the heading to the data property
                                        Clinician: "Provider", // Map the heading to the data property
                                        "Soap Notes": "", // Map the heading to the data property
                                    }}
                                    buttonRenderer={(item) => (
                                        <SoapNoteButton
                                            item={item}
                                            handleViewSoapClick={
                                                handleViewSoapClick
                                            }
                                        />
                                    )}
                                />
                            </div>
                        ) : (
                            <p className="text-gray-500">
                                Select a clinician to view the payroll report.
                            </p>
                        )}
                        {isSoapNoteModalOpen && (
                            <SoapNote
                                isOpen={isSoapNoteModalOpen}
                                onClose={closeSoapNoteModal}
                                isViewMode={isViewMode}
                                appointment={selectedAppointment}
                            />
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

function convertDateToSortableFormat(dateString) {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
}

export default PayrollReport;
