import React from "react";

const FullScreenSchedule = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 z-50">
            <div className="fixed inset-0 bg-white overflow-auto">
                <button
                    className="absolute top-0 right-0 m-4 text-2xl font-bold"
                    onClick={onClose}
                >
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
};

export default FullScreenSchedule;
