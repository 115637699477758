import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const config = {
    headers: {
        "Content-Type": "application/json",
        "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
        "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
        // Add other headers as needed
    },
};

export const fetchSchedule = createAsyncThunk(
    "schedule/fetchSchedule",
    async (_, { getstate }) => {
        const response = await axios.get(
            "https://portal.hearsay.ca/api/schedule",
            config
        );
        if (!response.status === 200) {
            throw new Error(
                response.data.message || "Could not fetch Todays Schedule"
            );
        }
        return response.data;
    }
)

export const fetchTodaysSchedule = createAsyncThunk(
    "schedule/fetchTodaysSchedule", 
    async (_, { getState }) => {
        const response = await axios.get(
            "https://portal.hearsay.ca/api/schedule/today",
            config
        );
        if (!response.status === 200) {
            throw new Error(
                response.data.message || "Could not fetch Todays Schedule"
            );
        }
        return response.data;
    }
);

export const fetchScheduleByDate = createAsyncThunk(
    "schedule/fetchScheduleByDate",
    async (date, { getState }) => {
        const response = await axios.get(
            `https://portal.hearsay.ca/api/schedule/${date}`,
            config
        );

        if (!response.status === 200) {
            throw new Error(
                response.data.message || "Could not fetch Schedule By Date"
            );
        }
        return response.data;
    }
);
