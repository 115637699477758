import { createSlice } from "@reduxjs/toolkit";
import {
    fetchSchedule,
    fetchTodaysSchedule,
    fetchScheduleByDate
} from "../Thunks/scheduleThunk";

const schduleSlice = createSlice({
    name: "schedule",
    initialState: {
        scheduleList: [],
        status: "idle", // Consider renaming this to fetchStatus if you want
        isLoading: false, // From editActiveAppointmentSlice
        error: null,
    },
    extraReducers: (builder) => {
        builder
            //
            // Fetch Schedule
            //
            .addCase(fetchSchedule.pending, (state) => {
                state.status = "loading";
                state.isLoading = true; // Set isLoading to true
            })
            .addCase(fetchSchedule.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false; // Set isLoading back to false when successful
                state.appointmentsList = action.payload;
            })
            .addCase(fetchSchedule.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false; // Set isLoading back to false when failed
                state.error = action.error.message;
            })
            //
            // Fetch All Appointments
            //
            .addCase(fetchTodaysSchedule.pending, (state) => {
                state.status = "loading";
                state.isLoading = true; // Set isLoading to true
            })
            .addCase(fetchTodaysSchedule.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false; // Set isLoading back to false when successful
                state.appointmentsList = action.payload;
            })
            .addCase(fetchTodaysSchedule.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false; // Set isLoading back to false when failed
                state.error = action.error.message;
            })
            //
            // Fetch Appointment By Client Id
            //
            .addCase(fetchScheduleByDate.pending, (state) => {
                state.status = "loading";
                state.isLoading = true; // Set isLoading to true
            })
            .addCase(fetchScheduleByDate.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false; // Set isLoading back to false when successful
                state.appointmentsList = action.payload.appointments;
            })
            .addCase(fetchScheduleByDate.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false; // Set isLoading back to false when failed
                state.error = action.error.message;
            })
    },
});

export default schduleSlice.reducer;
