import React, { useEffect, useRef } from 'react';

const ScheduleBody = ({ hours, clinicians, cellRefs, isAvailable, handleDragOver, handleDrop, handleRightClick, setClinicianWidths }) => {
    const clinicianRefs = useRef(clinicians.map(() => React.createRef()));

    useEffect(() => {
        const widths = clinicianRefs.current.map(ref => ref.current ? ref.current.offsetWidth : 0);
        setClinicianWidths(widths);  // Update state in CalTest2
    }, [clinicians]);

    return (
        <tbody>
            {hours.map((timeSlot, hourIndex) => (
                <tr key={timeSlot}>
                    <td className="px-4 py-2 text-sm font-medium text-gray-600">{timeSlot}</td>  {/* Display time slots */}
                    {clinicians.map((clinician, clinicianIndex) => (
                        <td
                            ref={cellRefs.current[hourIndex][clinicianIndex]}
                            key={`${clinician.id}-${timeSlot}`}
                            className={`px-4 py-2 ${isAvailable(timeSlot, clinician.id) ? 'bg-green-100' : 'bg-red-100'} border border-gray-300`}
                            onContextMenu={(e) => handleRightClick(e, clinician.id, timeSlot, hourIndex, clinicianIndex, cellRefs)}
                            onDragOver={handleDragOver}
                            onDrop={(e) => handleDrop(e, clinician.id, timeSlot)}
                        />
                    ))}
                </tr>
            ))}
        </tbody>
    );
};

export default ScheduleBody;
