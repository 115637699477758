const ClientBlock = ({ profile }) => {
    return (
        <>
            <div className="container">
                <div className="header">Client Block</div>
            </div>
        </>
    );
};

export default ClientBlock
