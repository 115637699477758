import { Person } from "@microsoft/mgt-react";
import React from "react";

const Home = () => {
  return (
    <>
      <h1>Home Page</h1>
      <Person person-query="me">
        <template>
          <div>
            <mgt-person-name></mgt-person-name>
          </div>
        </template>
      </Person>
    </>
  );
};

export default Home;
