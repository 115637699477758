import React from "react";
import ClientAvatar from "../../Components/ClientAvatar"; // Import your ClientAvatar component here

const ClientList = ({ filteredClients, handleClientClick }) => {
  return (
    <div className="min-h-0 flex-1 overflow-y-auto" aria-label="Directory">
      {Object.keys(filteredClients).map((letter) => (
        <div key={letter} className="relative">
          <div className="sticky top-0 z-10 border-b border-t border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
            <h3>{letter}</h3>
          </div>
          <ul
            role="list"
            className="relative z-0 divide-y divide-gray-200"
          >
            {filteredClients[letter].map((client) => (
              <li
                key={client.client_id}
                onClick={() => handleClientClick(client)}
              >
                <div className="relative flex items-center space-x-3 px-6 py-2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-50">
                  <div className="flex-shrink-0">
                    <ClientAvatar
                      name={client.client_name}
                      size={3} // Pass the size as a prop to ClientAvatar
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <div className="focus:outline-none">
                      {/* Extend touch target to the entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900 my-0">
                        {client.client_name}
                      </p>
                      <p className="truncate text-sm text-gray-500 my-0">
                        {client.client_id}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ClientList;
