import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
    Bars3Icon,
    CalendarIcon,
    CogIcon,
    HomeIcon,
    MagnifyingGlassCircleIcon,
    MapIcon,
    MegaphoneIcon,
    SquaresPlusIcon,
    UserGroupIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import {
    ChevronLeftIcon,
    EnvelopeIcon,
    FunnelIcon,
    MagnifyingGlassIcon,
    PhoneIcon,
} from "@heroicons/react/20/solid";
import { fetchClientsList } from "../../Store/Thunks/clientsThunk";
import { useDispatch, useSelector } from "react-redux";
import ClientAvatar from "../../Components/ClientAvatar";
import ClientProfile from "./ClientProfile";
import ClientProfileHeader from "./ClientProfileHeader";
import ClientTabs from "./ClientTabs";
import ClientList from "./ClientList";
import ClientDirectory from "./ClientDirectory";
import StripedTable from "../../Components/StripedTable";
import axios from "axios";
import { convertDateToSortableFormat } from "../../Util/sortableDate";
import ClientBlock from "./ClientBlock";

const user = {
    name: "Tom Cook",
    imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

const tabs = [
    { name: "Profile", href: "#", current: true },
    { name: "Appointments", href: "#", current: false },
    { name: "Blocks", href: "#", current: false },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function CombinedClient() {
    const [searchInput, setSearchInput] = useState("");
    const allClients = useSelector((state) => state.clients.clientsList);
    const [filteredClients, setFilteredClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState();
    const [selectedTab, setSelectedTab] = useState(tabs[0]); // Initialize with the first tab by default
    const [appointments, setAppointments] = useState([]);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const dispatch = useDispatch();

    const handleClientClick = (clickedClient) => {
        console.log("Clicked Client : " + JSON.stringify(clickedClient));
        setSelectedClient(clickedClient); // Use the clickedClient parameter here
    };

    const handleBackButtonClick = () => {
        // Set selectedClient to null when the back button is clicked
        setSelectedClient(null);
    };

    useEffect(() => {
        dispatch(fetchClientsList());
    }, [dispatch]);

    useEffect(() => {
        // Define an async function to fetch the data
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
                        "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
                        // Add other headers as needed
                    },
                };
                const response = await axios.get(
                    `https://portal.hearsay.ca/api/appointments/clients/${selectedClient.client_id}`, // Use the clientId in the URL
                    config
                );

                if (response.status !== 200) {
                    throw new Error(
                        response.data.message || "Could not fetch appointments"
                    );
                }

                const data = response.data;

                data.sort((a, b) => {
                    const dateA = convertDateToSortableFormat(a.Date);
                    const dateB = convertDateToSortableFormat(b.Date);
                    return dateB.localeCompare(dateA);
                });
                // Now you can handle the data, for example, set it in state
                // For this example, let's assume you have a setAppointments function
                setAppointments(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        // Call the fetchData function immediately
        if (selectedClient) {
            fetchData();
        }
    }, [selectedClient]);

    const updateFilteredClients = (searchInput, allClients) => {
        const filteredClients = allClients
            .filter((client) => {
                const clientName = client.client_name || "";
                return clientName
                    .toLowerCase()
                    .includes(searchInput.toLowerCase());
            })
            .sort((a, b) => {
                const nameA = (a.client_name || "").toLowerCase();
                const nameB = (b.client_name || "").toLowerCase();

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });

        // Organize the sortedClients into an object by the first letter of the name
        const clientsByLetter = {};

        filteredClients.forEach((client) => {
            const clientName = client.client_name || "";
            const firstLetter = clientName[0]?.toUpperCase(); // Use optional chaining to handle null/undefined
            if (firstLetter) {
                if (!clientsByLetter[firstLetter]) {
                    clientsByLetter[firstLetter] = [];
                }
                clientsByLetter[firstLetter].push(client);
            }
        });

        return clientsByLetter;
    };

    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);

        const filteredClients = updateFilteredClients(
            e.target.value,
            allClients
        );
        setFilteredClients(filteredClients);
    };

    useEffect(() => {
        // Initialize or update filteredClients based on allClients and search input
        const filteredClients = updateFilteredClients(searchInput, allClients);
        setFilteredClients(filteredClients);
    }, [allClients, searchInput]);

    return (
        <>
            <div className="flex h-full">
                <div className="flex min-w-0 flex-1 flex-col overflow-hidden h-full">
                    <div className="relative z-0 flex flex-1 overflow-hidden h-full">
                        <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                            <article>
                                {selectedClient && (
                                    <>
                                        <ClientProfileHeader
                                            selectedClient={selectedClient}
                                        />
                                        <div>
                                            <ClientTabs
                                                tabs={tabs}
                                                selectedTab={selectedTab}
                                                onTabClick={handleTabClick}
                                            />
                                            {selectedTab.name === "Profile" && (
                                                <ClientProfile
                                                    profile={selectedClient}
                                                />
                                            )}
                                            {selectedTab.name ===
                                                "Appointments" && (
                                                <StripedTable
                                                    title="Appointment List"
                                                    description="A table of client appointments"
                                                    headings={[
                                                        "Date",
                                                        "Client",
                                                        "Type",
                                                        "Scheduled Duration",
                                                        "Clinician",
                                                        "Soap Notes",
                                                    ]}
                                                    data={appointments}
                                                    showButton={false} // Set to false if you don't want the button
                                                    dataMapping={{
                                                        Date: "Date", // Map the heading to the data property
                                                        Client: "Client", // Map the heading to the data property
                                                        Type: "Type", // Map the heading to the data property
                                                        "Scheduled Duration":
                                                            "Schedule duration", // Map the heading to the data property
                                                        Clinician: "Provider", // Map the heading to the data property
                                                        "Soap Notes": "", // Map the heading to the data property
                                                    }}
                                                />
                                            )}
                                            {selectedTab.name === "Blocks" && (
                                                <ClientBlock
                                                    profile={selectedClient}
                                                />
                                            )}
                                            {/* Other content */}
                                        </div>
                                    </>
                                )}
                            </article>
                        </main>
                        <aside className="sticky top-0 w-96 flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col overflow-y">
                            <ClientDirectory
                                searchInput={searchInput}
                                handleSearchInputChange={
                                    handleSearchInputChange
                                }
                                allClients={allClients}
                            />
                            {/* Directory list */}
                            <ClientList
                                filteredClients={filteredClients}
                                handleClientClick={handleClientClick}
                            />
                        </aside>
                    </div>
                </div>
            </div>
        </>
    );
}
