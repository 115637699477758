import React from 'react';
import axios from 'axios';


function QuickBooksConnectButton() {
    const config = {
        headers: {
          "Content-Type": "application/json",
          "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
          "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
          // Add other headers as needed
        },
      };

    const initiateOAuth = async () => {
        try {
            // Call the backend to start the OAuth process
            const response = await axios.get('https://portal.hearsay.ca/api/startQuickBooksOAuth', config);
            if (response.data && response.data.authUrl) {
                // Redirect user to Intuit's OAuth page
                window.location.href = response.data.authUrl;
            }
        } catch (error) {
            console.error('Failed to initiate QuickBooks OAuth', error);
        }
    };

    return (
        <button onClick={initiateOAuth}>
            Connect to QuickBooks
        </button>
    );
}

export default QuickBooksConnectButton;
