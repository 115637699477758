import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Menu2 from "./Components/Menu2";
import { Login } from "@microsoft/mgt-react";
import useIsSignedIn from "./Auth/UseIsSignedIn";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ProtectedRoute from "./Auth/ProtectedRoute";
import LoginUser from "./Pages/Auth/Login";

library.add(faSpinner);

const App = () => {
    const isSignedIn = useIsSignedIn();
    console.log("isSignedIn:", isSignedIn);
    return (
        <Routes>
            <Route path="/login" element={<LoginUser />} />
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <Menu2 />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default App;
