import React from "react";

const Event = ({ event }) => {
    return (
        <li className="relative mt-px flex" style={{ gridRow: `${event.startRow} / span ${event.duration}` }}>
            <a href="#" className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5 bg-blue-50 hover:bg-blue-100">
                <p className="order-1 font-semibold text-blue-700">{event.title}</p>
                {event.description && (
                    <p className="order-1 text-blue-500 group-hover:text-blue-700">{event.description}</p>
                )}
                <p className="text-blue-500 group-hover:text-blue-700">
                    <time dateTime={event.time}>{event.displayTime}</time>
                </p>
            </a>
        </li>
    );
};

export default Event;
