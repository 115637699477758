import React from "react";
import { ChevronLeftIcon, EnvelopeIcon, FunnelIcon, MagnifyingGlassIcon, PhoneIcon } from '@heroicons/react/20/solid'

const ClientDirectory = ({
    searchInput,
    handleSearchInputChange,
    allClients,
}) => {
    return (
        <div className="px-6 pb-4 pt-6">
            <h2 className="text-lg font-medium text-gray-900">
                Client Directory
            </h2>
            <p className="mt-1 text-sm text-gray-600">
                Search directory of {allClients.length} clients
            </p>
            <form className="mt-6 flex gap-x-4" action="#">
                <div className="min-w-0 flex-1">
                    <label htmlFor="search" className="sr-only">
                        Search
                    </label>
                    <div className="relative rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </div>
                        <input
                            type="search"
                            name="search"
                            id="search"
                            value={searchInput}
                            className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-500 sm:text-sm sm:leading-6"
                            placeholder="Search"
                            onChange={handleSearchInputChange}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ClientDirectory;
