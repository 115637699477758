const isLastWeek = (date, asOfDate) => {
    const currentDate = asOfDate || new Date(); // Use asOfDate if provided, otherwise use the current date
    const lastWeekStart = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay() - 6
    );
    const lastWeekEnd = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay()
    );
    return date >= lastWeekStart && date <= lastWeekEnd;
};

export default isLastWeek;
