const IsPreviousWeek = (date, asOfDate) => {
    const currentDate = asOfDate || new Date(); // Use asOfDate if provided, otherwise use the current date
    const previousWeekStart = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay() - 13
    );
    const previousWeekEnd = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay() - 7
    );
    return date >= previousWeekStart && date <= previousWeekEnd;
};

export default IsPreviousWeek;
