import React from "react";

function generateInitials(name) {
  if (!name) {
    return ""; // Handle null or undefined name
  }

  const names = name.split(", "); // Assuming the name format is "LastName, FirstName"
  if (names.length < 2) {
    return ""; // Handle invalid name format
  }

  const lastNameInitial = names[0][0]?.toUpperCase() || ""; // Use optional chaining and provide a default value
  const firstNameInitial = names[1][0]?.toUpperCase() || ""; // Use optional chaining and provide a default value
  return `${firstNameInitial}${lastNameInitial}`;
}

function getColorForInitial(initial) {
  // Create a mapping of initials to colors
  const colorMap = {
    A: "bg-indigo-500",
    B: "bg-red-500",
    C: "bg-green-500",
    D: "bg-yellow-500",
    E: "bg-pink-500",
    F: "bg-purple-500",
    G: "bg-blue-500",
    H: "bg-teal-500",
    I: "bg-orange-500",
    J: "bg-indigo-600",
    K: "bg-red-600",
    L: "bg-green-600",
    M: "bg-yellow-600",
    N: "bg-pink-600",
    O: "bg-purple-600",
    P: "bg-blue-600",
    Q: "bg-teal-600",
    R: "bg-orange-600",
    S: "bg-indigo-700",
    T: "bg-red-700",
    U: "bg-green-700",
    V: "bg-yellow-700",
    W: "bg-pink-700",
    X: "bg-purple-700",
    Y: "bg-blue-700",
    Z: "bg-teal-700",
    // Add more mappings as needed
  };

  // Default to a fallback color if the initial is not in the mapping
  return colorMap[initial] || "bg-gray-500"; // You can change "bg-gray-500" to your preferred fallback color
}

const ClientAvatar = ({ name, size }) => {
  const initials = generateInitials(name);
  const firstLetter = initials[1];
  const colorClass = getColorForInitial(firstLetter);

  const avatarSize = size || 10; // Default size is 10 if size prop is not provided

  const fontSize = Math.round((avatarSize / 10) * 4); // Adjust font size based on size prop

  const styles = {
    width: `${avatarSize}rem`,
    height: `${avatarSize}rem`,
    fontSize: `${fontSize}rem`, // Adjust font size
  };

  return (
    <div className="flex-shrink-0">
      <div
        className={`rounded-full flex items-center justify-center text-white font-semibold ${colorClass}`}
        style={styles}
      >
        {initials}
      </div>
    </div>
  );
};

export default ClientAvatar;
