// Helper function to check if a date falls within the previous month
const isPreviousMonth = (date, asOfDate) => {
    const currentDate = asOfDate || new Date(); // Use asOfDate if provided, otherwise use the current date
    const previousMonthYear = currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    const previousMonth = currentDate.getMonth() === 0 ? 11 : currentDate.getMonth() - 1;

    const previousMonthStart = new Date(
        previousMonthYear,
        previousMonth,
        1
    );
    const previousMonthEnd = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
    );

    return date >= previousMonthStart && date <= previousMonthEnd;
};

export default isPreviousMonth;

