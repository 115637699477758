// ClinicianHeader.js
import React from 'react';

const ClinicianHeader = ({ clinician }) => {
    return (
        <th className="border px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-white">
            <div className="flex items-center space-x-3">
                <img
                    src={clinician.pictureUrl}
                    alt={`Dr. ${clinician.name}`}
                    className="h-10 w-10 rounded-full"
                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://via.placeholder.com/40'; }}
                />
                <div>
                    <div className="font-bold">{clinician.name}</div>
                    <div className="text-sm text-gray-500">{clinician.credentials}</div>
                </div>
            </div>
        </th>
    );
};

export default ClinicianHeader;
