import React from 'react';

const CalendarIcon = ({ onOpen }) => {
  return (
    <button
      onClick={onOpen}
      className="fixed bottom-4 right-4 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-200"
      aria-label="Open Calendar"
    >
      📅
    </button>
  );
};

export default CalendarIcon;