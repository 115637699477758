export const appointmentTypes = [
    { type: 'Annual HT & HA check', category: 'Audiology', appType: '' },
    { type: 'APD Test #2', category: 'Auditory Processing', appType: '' },
    { type: 'Speech and Language Therapy - 60 mins\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'ASpeech and Language Therapy - 60 mins\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Cerumen Management', category: 'Audiology', appType: '' },
    { type: 'Fitting (Exp. User)', category: 'Audiology', appType: 'Fitting' },
    { type: 'Fitting (New User)', category: 'Audiology', appType: 'Fitting' },
    { type: 'Fitting (Trial Aids)', category: 'Audiology', appType: 'Fitting' },
    { type: 'Hearing Aid Adjustment', category: 'Audiology', appType: 'HearingAidAdjustment' },
    { type: 'Hearing Aid Check', category: 'Audiology', appType: 'HearingAidCheck' },
    { type: 'Hearing Aid Cleaning', category: 'Audiology', appType: 'HearingCleaning' },
    { type: 'Hearing Aid Consult', category: 'Audiology', appType: 'HearingConsult' },
    { type: 'Hearing Test (Adult)', category: 'Audiology', appType: 'HearingTest' },
    { type: 'Hearing Test (Child)', category: 'Audiology', appType: 'HearingTest' },
    { type: 'Hearing Test (Senior)', category: 'Audiology', appType: 'HearingTest' },
    { type: 'H.T. recheck (Child)', category: 'Audiology', appType: 'HearingTest' },
    { type: 'Initial Intake and Screener - 6+ years', category: 'Speech Therapy', appType: 'Intake' },
    { type: 'Initial Speech Intake Call - 0 to 5 years', category: 'Speech Therapy', appType: 'Intake' },
    { type: 'Joint Supervision with CDA', category: 'Ignore', appType: '' },
    { type: 'Literacy coaching 30 min', category: 'Speech Therapy', appType: '' },
    { type: 'LUNCH', category: 'Ignore', appType: '' },
    { type: 'Meeting - in clinic', category: 'Ignore', appType: '' },
    { type: 'Meeting - ZOOM', category: 'Ignore', appType: '' },
    { type: 'Other', category: 'Ignore', appType: '' },
    { type: 'Out of office', category: 'Ignore', appType: '' },
    { type: 'Phone appt.', category: 'Ignore', appType: '' },
    { type: 'P/U Earmolds', category: 'Audiology', appType: '' },
    { type: 'Repair', category: 'Audiology', appType: '' },
    { type: 'Room Rental', category: 'Ignore', appType: '' },
    { type: 'Speech and Language Therapy - 15 min\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language therapy - 20min\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language  Therapy - 25 min\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 30 Mins\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 45 Mins\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 60 Mins\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 90 Mins\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 25 min\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 25 min', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 30 Mins', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 45 Mins', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 60 Mins', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 90 Mins', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech Consultation', category: 'Speech Therapy', appType: 'Consult'},
    { type: 'Sp-Lang. Assessment', category: 'Speech Therapy', appType: 'Assessment' },
    { type: 'Student coaching 30min', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Student coaching 60min', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Training Speech and Language Therapy - 15min\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Training Speech and Language Therapy - 20min\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Trial Period Eval. (TPE)', category: 'Audiology', appType: '' },
    { type: 'VFast ForWord - billing reminder', category: 'Ignore', appType: '' },
    { type: 'VLiteracy coaching 30 min', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'VLiteracy coaching 60 min', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'VSpeech and Language Therapy - 30 min\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'VSpeech and Language therapy - 45 min\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'VSpeech and Language therapy - 60 min\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'VSpeech and Language Therapy - 60 min', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'VSpeech and Language therapy - 90 min\'', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Webinar/Seminar/Workshop', category: 'Ignore', appType: '' },
    { type: 'WSIB follow up (TPE-90day)', category: 'Audiology', appType: '' },
    { type: 'ASpeech and Language Therapy - 60 mins', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language therapy - 20min', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 25 min', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'Speech and Language Therapy - 30 Mins', category: 'Speech Therapy', appType: 'Therapy' },
    { type: 'ASpeech and Language Therapy - 60 mins', category: 'Speech Therapy', appType: 'Therapy' },
    { type: "Annual HT (Adult)", category: "Audiology", appType: '' },
    { type: "Annual HT (Senior)", category: "Audiology", appType: '' },
    { type: "APD Consultation call #1", category: "Auditory Processing", appType: '' },
    { type: "APD Test Results call #3", category: "Auditory Processing", appType: '' },
    { type: "Booth occupied", category: "Ignore", appType: '' },
    { type: "Borrow our iPad in clinic", category: "Ignore", appType: '' },
    { type: "Exchange Hearing Aids", category: "Audiology", appType: '' },
    { type: "Hearing Aid Evaluation (HAE)", category: "Audiology", appType: '' },
    { type: "Hearing Test (0-8mths)", category: "Audiology", appType: '' },
    { type: "Hearing test & HAE", category: "Audiology", appType: '' },
    { type: "HOLD - Offered to a client, waiting for confirmation", category: "Ignore", appType: '' },
    { type: "Holiday", category: "Ignore", appType: '' },
    { type: "Impressions", category: "Audiology", appType: '' },
    { type: "Impressions Swimplugs", category: "Audiology", appType: '' },
    { type: "Joint appt w Clinician", category: "Ignore", appType: '' },
    { type: "Late Cancellation", category: "Ignore", appType: '' },
    { type: "LGHA Consult", category: "Auditory Processing", appType: '' },
    { type: "Literacy and Language Assessment", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Literacy Coaching 45 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Literacy coaching 60 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Literacy coaching - parent 30min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Mentorship CASLPO", category: "Ignore", appType: '' },
    { type: "Mentorship Contract", category: "Ignore", appType: '' },
    { type: "***PLEASE CHOOSE EVENT TYPE***", category: "Ignore", appType: '' },
    { type: "Problem", category: "Audiology", appType: '' },
    { type: "P/U Accessory", category: "Audiology", appType: '' },
    { type: "P/U Aids", category: "Audiology", appType: '' },
    { type: "P/U musician plugs", category: "Audiology", appType: '' },
    { type: "P/U Swimplugs", category: "Audiology", appType: '' },
    { type: "Reports/Files", category: "Ignore", appType: '' },
    { type: "Return Hearing Aid/s", category: "Audiology", appType: '' },
    { type: "Sick - Out of Office", category: "Ignore", appType: '' },
    { type: "SLP Re-Assessment", category: "Speech Therapy", appType: 'Assessment' },
    { type: "Speech and Language Therapy - 15 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Student coaching 45min", category: "Ignore", appType: 'Therapy' },
    { type: "Training Speech and Language Therapy - 15min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Training Speech and Language Therapy - 20min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Training Speech and Language Therapy - 30 min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Trial Period Eval (TPE) - LGHA", category: "Auditory Processing", appType: '' },
    { type: "Tubing change", category: "Audiology", appType: '' },
    { type: "Tympanometry", category: "Audiology", appType: '' },
    { type: "VSpeech and Language Therapy - 120 min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VSpeech and Language Therapy - 30 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VSpeech and Language Therapy - 45 min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VTraining Speech and Language Therapy - 30min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VTraining Speech and Language Therapy - 45min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VTraining Speech and Language Therapy - 45min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Hearing test  & HAE", category: "Audiology", appType: '' },
    { type: "VNG Test", category: "Audiology", appType: '' },
    { type: "Hearing test & Impressions", category: "Audiology", appType: '' },
    { type: "Progress Report Reminder", category: "Ignore", appType: '' },
    { type: "VMetronome - billing reminder", category: "Ignore", appType: '' },
    { type: "Vestibular - full assessment", category: "Audiology", appType: '' },
    { type: "Vacation Day", category: "Ignore", appType: '' },
    { type: "Training Speech and Language Therapy - 30 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "No Show", category: "Ignore", appType: '' },
    { type: "Martindale Visit", category: "Audiology", appType: '' },
    { type: "Joint appt - Audiology", category: "Ignore", appType: '' },
    { type: "Hearing Test (0-11mths)", category: "Audiology", appType: '' },
    { type: "Fitting FM", category: "Audiology", appType: '' },
    { type: "VSpeech and Language Therapy - 60 mins'", category: "Auditory Processing", appType: 'Therapy' },
    { type: "ASpeech and Language Therapy - 45 mins'", category: "Auditory Processing", appType: 'Therapy' },
    { type: "ASpeech and Language Therapy - 30 mins'", category: "Auditory Processing", appType: 'Therapy' },
    { type: "AVSpeech and Language Therapy - 60 mins'", category: "Auditory Processing", appType: 'Therapy' },
    { type: "ABR - Neurodiagnostic", category: "Audiology", appType: '' },
    { type: "APD Re-Assessment", category: "Auditory Processing", appType: 'Assessment' },
    { type: "Aural Rehabilitation", category: "Auditory Processing", appType: '' },
    { type: "Speech and Language Therapy - 120 Mins'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Speech Group Session (5 days)", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Staff meeting", category: "Ignore", appType: '' },
    { type: "VEMPs", category: "Audiology", appType: '' },
    { type: "At Cory's", category: "Ignore", appType: '' },
    { type: "ABR - frequency specific", category: "Audiology", appType: '' },
    { type: "ABR - Screening", category: "Audiology", appType: '' },
    { type: "ABR - frequency specific", category: "Audiology", appType: '' },
    { type: "Annual HT & HA Check", category: "Audiology", appType: '' },
    { type: "APD Therapy - 60min'", category: "Auditory Processing", appType: 'Therapy' },
    { type: "Assessment", category: "Speech Therapy", appType: 'Assessment' },
    { type: "CCAC Speech Therapy - 45 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "CDA Supervision Meeting", category: "Audiology", appType: '' },
    { type: "Fast ForWord", category: "Speech Therapy", appType: '' },
    { type: "FM Consult", category: "Audiology", appType: '' },
    { type: "Hearing Screening", category: "Audiology", appType: '' },
    { type: "Hearing test", category: "Audiology", appType: '' },
    { type: "Hearing Test (9mths - 16yrs)", category: "Audiology", appType: '' },
    { type: "Literacy Coaching 90 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Literacy Group (5 days)", category: "Speech Therapy", appType: '' },
    { type: "Other imported event", category: "Ignore", appType: '' },
    { type: "Return LGHA", category: "Auditory Processing", appType: '' },
    { type: "Speech and Language Therapy - 120 Mins", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Speech and Language therapy - 30 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Speech and Language therapy - 45 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Speech and Language therapy - 60 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Sp-Lang-Listening Therapy - 60 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Student Coaching 15min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Student coaching parent 15min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Vacation", category: "Ignore", appType: '' },
    { type: "VCCAC Speech Therapy - 45 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VCognitive Therapy - 90min", category: "Auditory Processing", appType: 'Therapy' },
    { type: "Vestibular - Rehabilitation/Session", category: "Audiology", appType: '' },
    { type: "VFast ForWord", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VFast ForWord 30min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "vHIT", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VLiteracy and Language Assessment", category: "Speech Therapy", appType: 'Assessment' },
    { type: "VSpeech and Language Therapy - 15 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VSpeech and Language Therapy - 15 min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VSpeech and Language Therapy - 25 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VSpeech and Language Therapy - 25 min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VSpeech and Language Therapy - 45 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VSP-Lang-Listening Therapy - 30 min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VSp Lang Listening Therapy 60 min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VSP-Lang-Listening Therapy - 60 min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VTraining Speech and Language Therapy - 15min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VTraining Speech and Language Therapy - 15min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VTraining Speech and Language Therapy - 20min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "VTraining Speech and Language Therapy - 20min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "Student Coaching 90min", category: "Speech Therapy", appType: 'Therapy' },
    { type: "APD Consult call - Therapy only", category: "Auditory Processing", appType: 'Consult' },
    { type: "Service Warranty - need loaner", category: "Ignore", appType: '' },
    { type: "At Cory's", category: "Ignore", appType: '' },
    { type: "Service Warranty - need loaner", category: "Ignore", appType: '' },
    { type: "Birkdale Visit", category: "Audiology", appType: '' },
    { type: "Hearing Test & Vestibular Screening Test", category: "Audiology", appType: 'Assessment' },
    { type: "Literacy and Language Screener", category: "Speech Therapy", appType: 'Assessment' },
    { type: "Season's Visit", category: "Audiology", appType: '' },
    { type: "Service Warranty - need loaners", category: "Ignore", appType: '' },
    { type: "Training Speech and Language Therapy - 45min'", category: "Speech Therapy", appType: 'Therapy' },
    { type: "FREE HT & Trial aid offer", category: "Audiology", appType: '' },
    { type: "APD Test #2a", category: "Auditory Processing", appType: '' },
    { type: "APD Test #2b", category: "Auditory Processing", appType: '' },
    { type: "WSIB follow up (TPE)", category: "Audiology", appType: '' },
    { type: "Home visit", category: "Ignore", appType: '' },
    { type: "Diagnostic DPOAEs", category: "Audiology", appType: '' },
    { type: "APD Test #4a", category: "Auditory Processing", appType: '' },
  ];