import { createSlice } from "@reduxjs/toolkit";
import { fetchCliniciansList } from "../Thunks/cliniciansThunk";

const cliniciansSlice = createSlice({
    name: "clinicians",
    initialState: {
        cliniciansList: [],
        status: "idle", // Consider renaming this to fetchStatus if you want
        isLoading: false, // From editActiveAppointmentSlice
        error: null,
    },
    extraReducers: (builder) => {
        builder
            // fetchCliniciansList thunks
            .addCase(fetchCliniciansList.pending, (state) => {
                state.status = "loading";
                state.isLoading = true; // Set isLoading to true
            })
            .addCase(fetchCliniciansList.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false; // Set isLoading back to false when successful
                state.cliniciansList = action.payload;
            })
            .addCase(fetchCliniciansList.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false; // Set isLoading back to false when failed
                state.error = action.error.message;
            });
    },
});

export default cliniciansSlice.reducer;
