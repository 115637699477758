import React, { useEffect, useState } from "react";
import WeekSelectorDropdown from "../../Components/WeekSelectorDropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchWaagTotals } from "../../Store/Thunks/invoicesThunk";
import { reduceAppointments } from "./reduceAppointments";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { fetchAppointmentsList } from "../../Store/Thunks/appointmentsThunk";
import { fetchSchedule } from "../../Store/Thunks/scheduleThunk";

const WAAGReport = ({ data }) => {
    const [selectedWeek, setSelectedWeek] = useState(null);
    const waagTotals = useSelector((state) => state.invoices.waagTotals);
    const appointments = useSelector(
        (state) => state.appointments.appointmentsList?.appointments
    );
    const schedule = useSelector((state) => state.schedule.scheduleList );
    const [reducedAppointments, setReducedAppointments] = useState({});
    const [activeClient30Days, setActiveClients30Days] = useState(0);
    const [activeClient60Days, setActiveClients60Days] = useState(0);
    // const [activeClient60Days, setActiveClients60Days] = useState(0);

    const [chartData, setChartData] = useState({
        labels: [], // Initialize with empty labels
        datasets: [], // Initialize with empty datasets
    });
    const [speechChartData, setSpeechChartData] = useState({
        labels: [], // Initialize with empty labels
        datasets: [], // Initialize with empty datasets
    });
    const [audiologyChartData, setAudiologyChartData] = useState({
        labels: [], // Initialize with empty labels
        datasets: [], // Initialize with empty datasets
    });
    const dispatch = useDispatch();

    const handleSelectWeek = (week) => {
        setSelectedWeek(week);
    };

    useEffect(() => {
        dispatch(fetchWaagTotals());
        dispatch(fetchSchedule());
    }, [dispatch]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: "Weekly Revenue Comparison",
            },
        },
    };

    useEffect(() => {
        if (schedule) {
            let {
                summary,
                activeClients30DaysCount,
                activeClients60DaysCount,
                thirtyDayActiveLast365,
            } = reduceAppointments(schedule);
            console.log(
                "DATA " + JSON.stringify(summary),
                activeClients30DaysCount,
                activeClients60DaysCount
            );
            setReducedAppointments(summary);
            setActiveClients30Days(activeClients30DaysCount);
            setActiveClients60Days(activeClients60DaysCount);
        }

        if (waagTotals?.chart?.chartRevenue) {
            const labels = Array.from(
                { length: 52 },
                (_, i) => `Week ${i + 1}`
            );
            const datasets = [
                {
                    label: "Revenue FY2023",
                    data:
                        waagTotals.chart.chartRevenue.weeklyRevenueFY2023 || [],
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
                {
                    label: "Revenue FY2024",
                    data:
                        waagTotals.chart.chartRevenue.weeklyRevenueFY2024 || [],
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
            ];

            setChartData({ labels, datasets });
        }

        if (waagTotals?.chart?.chartRevenue) {
            const labels = Array.from(
                { length: 52 },
                (_, i) => `Week ${i + 1}`
            );
            const datasets = [
                {
                    label: "Audiology Revenue FY2023",
                    data:
                        waagTotals.chart.chartRevenue
                            .weeklyAudiologyRevenueFY2023 || [],
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
                {
                    label: "Audiology Revenue FY2024",
                    data:
                        waagTotals.chart.chartRevenue
                            .weeklyAudiologyRevenueFY2024 || [],
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
            ];

            setAudiologyChartData({ labels, datasets });
        }

        if (waagTotals?.chart?.chartRevenue) {
            const labels = Array.from(
                { length: 52 },
                (_, i) => `Week ${i + 1}`
            );
            const datasets = [
                {
                    label: "Speech Revenue FY2023",
                    data:
                        waagTotals.chart.chartRevenue
                            .weeklySpeechRevenueFY2023 || [],
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                },
                {
                    label: "Speech Revenue FY2024",
                    data:
                        waagTotals.chart.chartRevenue
                            .weeklySpeechRevenueFY2024 || [],
                    borderColor: "rgb(53, 162, 235)",
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
            ];

            setSpeechChartData({ labels, datasets });
        }
    }, [waagTotals, schedule]);

    // useEffect to log and use reducedAppointments after it's updated
    useEffect(() => {
        console.log(
            "reducedAppointments " + JSON.stringify(reducedAppointments)
        );
        console.log(
            "reducedAppointments.Consult " +
                JSON.stringify(reducedAppointments.Consult)
        );
        console.log(
            "reducedAppointments.Consult " +
                JSON.stringify(reducedAppointments.Consult?.lastWeekCount)
        );
        // This will now correctly log the updated state after appointments change and reducedAppointments is set
    }, [reducedAppointments]); // Depend on reducedAppointments

    return (
        <div className="bg-white shadow-lg rounded-lg p-6">
            <h1 className="text-2xl font-bold text-gray-900 mb-4">
                WAAG Report
            </h1>
            <WeekSelectorDropdown onSelectWeek={handleSelectWeek} />
            {selectedWeek && (
                <h2 className="text-xl text-gray-700 my-2">
                    Week Of - {selectedWeek.startDate.toDateString()} -{" "}
                    {selectedWeek.endDate.toDateString()}
                </h2>
            )}
            <section className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-3">
                    Audiology
                </h3>
                <div className="overflow-x-auto">
                    <table className="min-w-full table-auto text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th className="py-3 px-6">Metric</th>
                                <th className="py-3 px-6">Last Week</th>
                                <th className="py-3 px-6">Prev Week</th>
                                <th className="py-3 px-6">MTD</th>
                                <th className="py-3 px-6">Last Month</th>
                                <th className="py-3 px-6">Target %</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total Revenue</td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology.audiologyRevenue.lastWeek.toLocaleString(
                                              "en-US",
                                              {
                                                  style: "currency",
                                                  currency: "USD",
                                              }
                                          )
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology.audiologyRevenue.prevWeek.toLocaleString(
                                              "en-US",
                                              {
                                                  style: "currency",
                                                  currency: "USD",
                                              }
                                          )
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology.audiologyRevenue.MTD.toLocaleString(
                                              "en-US",
                                              {
                                                  style: "currency",
                                                  currency: "USD",
                                              }
                                          )
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology.audiologyRevenue.prevMonth.toLocaleString(
                                              "en-US",
                                              {
                                                  style: "currency",
                                                  currency: "USD",
                                              }
                                          )
                                        : "Loading..."}
                                </td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total Phone Calls</td>
                                <td className="py-4 px-6">1</td>
                                <td className="py-4 px-6">2</td>
                                <td className="py-4 px-6">3</td>
                                <td className="py-4 px-6">4</td>
                                <td className="py-4 px-6">5</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">
                                    Total Appointments
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology
                                              .audiologyAppointments.lastWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology
                                              .audiologyAppointments.prevWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology
                                              .audiologyAppointments.MTD
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology
                                              .audiologyAppointments.prevMonth
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">0</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total New Clients</td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology
                                              .audiologyNewClients.lastWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology
                                              .audiologyNewClients?.prevWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology
                                              .audiologyNewClients?.MTD
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology
                                              .audiologyNewClients?.prevMonth
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">0</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total Qualified</td>
                                <td className="py-4 px-6">1</td>
                                <td className="py-4 px-6">2</td>
                                <td className="py-4 px-6">3</td>
                                <td className="py-4 px-6">4</td>
                                <td className="py-4 px-6">5</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total Ordered</td>
                                <td className="py-4 px-6">1</td>
                                <td className="py-4 px-6">2</td>
                                <td className="py-4 px-6">3</td>
                                <td className="py-4 px-6">4</td>
                                <td className="py-4 px-6">5</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total Units Fit</td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology.hearingAidCounts
                                              .lastWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology.hearingAidCounts
                                              .prevWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology.hearingAidCounts
                                              .MTD
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.audiology
                                        ? waagTotals.audiology.hearingAidCounts
                                              .prevMonth
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6"></td>
                            </tr>
                            {/* Repeat for other metrics */}
                        </tbody>
                    </table>
                </div>
            </section>
            <section>
                <h3 className="text-lg font-semibold text-gray-800 mb-3">
                    Speech
                </h3>
                <div className="overflow-x-auto">
                    <table className="min-w-full table-auto text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th className="py-3 px-6">Metric</th>
                                <th className="py-3 px-6">Last Week</th>
                                <th className="py-3 px-6">Prev Week</th>
                                <th className="py-3 px-6">MTD</th>
                                <th className="py-3 px-6">Last Month</th>
                                <th className="py-3 px-6">Target %</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total Revenue</td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechRevenue.lastWeek.toLocaleString(
                                              "en-US",
                                              {
                                                  style: "currency",
                                                  currency: "USD",
                                              }
                                          )
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechRevenue.prevWeek.toLocaleString(
                                              "en-US",
                                              {
                                                  style: "currency",
                                                  currency: "USD",
                                              }
                                          )
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechRevenue.MTD.toLocaleString(
                                              "en-US",
                                              {
                                                  style: "currency",
                                                  currency: "USD",
                                              }
                                          )
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechRevenue.prevMonth.toLocaleString(
                                              "en-US",
                                              {
                                                  style: "currency",
                                                  currency: "USD",
                                              }
                                          )
                                        : "Loading..."}
                                </td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total Phone Calls</td>
                                <td className="py-4 px-6">1</td>
                                <td className="py-4 px-6">2</td>
                                <td className="py-4 px-6">3</td>
                                <td className="py-4 px-6">4</td>
                                <td className="py-4 px-6">5</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">
                                    Total Appointments
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechAppointments
                                              .lastWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechAppointments
                                              .prevWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechAppointments
                                              .MTD
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechAppointments
                                              .prevMonth
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">0</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total Intake</td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Intake
                                        ? reducedAppointments.Intake
                                              .lastWeekCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Intake
                                        ? reducedAppointments.Intake
                                              .previousWeekCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Intake
                                        ? reducedAppointments.Intake
                                              .totalThisMonthCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Intake
                                        ? reducedAppointments.Intake
                                              .totalLastMonthCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">5</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total Consults</td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Consult
                                        ? reducedAppointments.Consult
                                              .lastWeekCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Consult
                                        ? reducedAppointments.Consult
                                              .previousWeekCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Consult
                                        ? reducedAppointments.Consult
                                              .totalThisMonthCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Consult
                                        ? reducedAppointments.Consult
                                              .totalLastMonthCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">0</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total Assessments</td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Assessment
                                        ? reducedAppointments.Assessment
                                              .lastWeekCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Assessment
                                        ? reducedAppointments.Assessment
                                              .previousWeekCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Assessment
                                        ? reducedAppointments.Assessment
                                              .totalThisMonthCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {reducedAppointments.Assessment
                                        ? reducedAppointments.Assessment
                                              .totalLastMonthCount
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">5</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">
                                    Total Therapy Hours
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechTherapyHours
                                              .lastWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechTherapyHours
                                              .prevWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechTherapyHours
                                              .MTD
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechTherapyHours
                                              .prevMonth
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">5</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">
                                    Active Clients - 60 Days
                                </td>
                                <td className="py-4 px-6">
                                    {activeClient60Days}
                                </td>
                                <td className="py-4 px-6"></td>
                                <td className="py-4 px-6"></td>
                                <td className="py-4 px-6"></td>
                                <td className="py-4 px-6"></td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">
                                    Active Clients - 30 Days
                                </td>
                                <td className="py-4 px-6">
                                    {activeClient30Days}
                                </td>
                                <td className="py-4 px-6"></td>
                                <td className="py-4 px-6"></td>
                                <td className="py-4 px-6"></td>
                                <td className="py-4 px-6"></td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Total New Clients</td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechNewClients
                                              .lastWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechNewClients
                                              .prevWeek
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechNewClients.MTD
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">
                                    {waagTotals.speech
                                        ? waagTotals.speech.speechNewClients
                                              .prevMonth
                                        : "Loading..."}
                                </td>
                                <td className="py-4 px-6">0</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">
                                    Total Drop Off - 30 Days
                                </td>
                                <td className="py-4 px-6">1</td>
                                <td className="py-4 px-6">2</td>
                                <td className="py-4 px-6">3</td>
                                <td className="py-4 px-6">4</td>
                                <td className="py-4 px-6">5</td>
                            </tr>
                            <tr className="border-b bg-white hover:bg-gray-50">
                                <td className="py-4 px-6">Net Clients</td>
                                <td className="py-4 px-6">1</td>
                                <td className="py-4 px-6">2</td>
                                <td className="py-4 px-6">3</td>
                                <td className="py-4 px-6">4</td>
                                <td className="py-4 px-6">5</td>
                            </tr>
                            {/* Repeat for other metrics */}
                        </tbody>
                    </table>
                </div>
            </section>
            <Line data={chartData} options={options} />
            <Line data={speechChartData} options={options} />
            <Line data={audiologyChartData} options={options} />
            {/* Additional sections as needed, following the same pattern for consistency */}
        </div>
    );
};

export default WAAGReport;
