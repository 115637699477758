import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const config = {
    headers: {
        "Content-Type": "application/json",
        "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
        "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
        // Add other headers as needed
    },
};

export const fetchAppointmentsList = createAsyncThunk(
    "appointments/fetchAppointmentsList",
    async (_, { getState }) => {
        const response = await axios.get(
            `https://portal.hearsay.ca/api/appointments`,
            config
        );
        console.log("RESPONSE" + JSON.stringify(response));
        if (!response.status === 200) {
            throw new Error(
                response.data.message || "Could not fetch appointments"
            );
        }

        return response.data;
    }
);

export const fetchAppointmentsByClientId = createAsyncThunk(
    "appointments/fetchAppointmentsByClientId",
    async (clientId, { getState }) => {
        // Accept the clientId as an argument

        const response = await axios.get(
            `https://portal.hearsay.ca/api/appointments/clients/${clientId}`, // Use the clientId in the URL
            config
        );

        if (response.status !== 200) {
            // Check the status code for success
            throw new Error(
                response.data.message || "Could not fetch appointments"
            );
        }

        return response.data;
    }
);

export const fetchAppointmentsByClinician = createAsyncThunk(
    "appointments/fetchAppointmentsByClinician",
    async (clinicianId, { getState }) => {
        const response = await axios.get(
            `https://portal.hearsay.ca/api/appointments/clinicians/${clinicianId}`,
            config
        );

        if (response.status !== 200) {
            throw new Error(
                response.data.message ||
                    "Could not fetch appointments by clinician"
            );
        }

        return response.data;
    }
);

export const saveSoapNote = createAsyncThunk(
    "appointments/saveSoapNote",
    async ([appointment, soapNote], { getState }) => {
        console.log(
            `saveSoapNote appointment: ${JSON.stringify(
                appointment
            )}, soapNote: ${JSON.stringify(soapNote)}`
        );
        const response = await axios.post(
            "https://portal.hearsay.ca/api/appointments/saveSoap",
            {
                appointment,
                soapNote,
            },
            config
        );
        if (response.status !== 200) {
            throw new Error(
                response.data.message || "Could not save soap note"
            );
        }

        return response.data;
    }
);

export const fetchAppointmentsByDateRange = createAsyncThunk(
    "appointments/fetchAppointmentsByDateRange",
    async ({ startDate, endDate }, { getState }) => {
        try {
            // Make a GET request with the start and end date parameters
            const response = await axios.post(
                `https://portal.hearsay.ca/api/appointments/payroll`, // Adjust the API endpoint
                {
                    startDate,
                    endDate,
                },
                config
            );

            if (response.status !== 200) {
                throw new Error(
                    response.data.message ||
                        "Could not fetch appointments by date range"
                );
            }

            return response.data;
        } catch (error) {
            console.error("Error fetching appointments by date range:", error);
            throw error;
        }
    }
);
