import { createSlice } from "@reduxjs/toolkit";
import {
    fetchAppointmentsList,
    fetchAppointmentsByClientId,
    fetchAppointmentsByClinician,
    fetchAppointmentsByDateRange
} from "../Thunks/appointmentsThunk";

const appointmentsSlice = createSlice({
    name: "appointments",
    initialState: {
        appointmentsList: [],
        status: "idle", // Consider renaming this to fetchStatus if you want
        isLoading: false, // From editActiveAppointmentSlice
        error: null,
    },
    extraReducers: (builder) => {
        builder
            //
            // Fetch All Appointments
            //
            .addCase(fetchAppointmentsList.pending, (state) => {
                state.status = "loading";
                state.isLoading = true; // Set isLoading to true
            })
            .addCase(fetchAppointmentsList.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false; // Set isLoading back to false when successful
                state.appointmentsList = action.payload;
            })
            .addCase(fetchAppointmentsList.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false; // Set isLoading back to false when failed
                state.error = action.error.message;
            })
            //
            // Fetch Appointment By Client Id
            //
            .addCase(fetchAppointmentsByClientId.pending, (state) => {
                state.status = "loading";
                state.isLoading = true; // Set isLoading to true
            })
            .addCase(fetchAppointmentsByClientId.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.isLoading = false; // Set isLoading back to false when successful
                state.appointmentsList = action.payload.appointments;
            })
            .addCase(fetchAppointmentsByClientId.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false; // Set isLoading back to false when failed
                state.error = action.error.message;
            })
            //
            // Fetch Appointments by Clinician Name
            //
            .addCase(fetchAppointmentsByClinician.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(
                fetchAppointmentsByClinician.fulfilled,
                (state, action) => {
                    state.status = "succeeded";
                    state.isLoading = false;
                    state.appointmentsList = action.payload;
                }
            )
            .addCase(fetchAppointmentsByClinician.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false;
                state.error = action.error.message;
            })
            //
            // Fetch Appointments by Date Range
            //
            .addCase(fetchAppointmentsByDateRange.pending, (state) => {
                state.status = "loading";
                state.isLoading = true;
            })
            .addCase(
                fetchAppointmentsByDateRange.fulfilled,
                (state, action) => {
                    state.status = "succeeded";
                    state.isLoading = false;
                    state.appointmentsList = action.payload;
                }
            )
            .addCase(fetchAppointmentsByDateRange.rejected, (state, action) => {
                state.status = "failed";
                state.isLoading = false;
                state.error = action.error.message;
            });
    },
});

export default appointmentsSlice.reducer;
