import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import appointmentsReducer from "./Slices/appointmentsSlice"; 
import cliniciansReducer from "./Slices/cliniciansSlice"; 
import clientsReducer from "./Slices/clientsSlice"; 
import invoiceReducer from "./Slices/invoicesSlice";
import scheduleReducer from "./Slices/scheduleSlice";

const persistConfig = {
  key: "HearSay",
  storage,
};

const rootReducer = persistReducer(
  persistConfig,
  combineReducers({
    appointments: appointmentsReducer,
    clinicians: cliniciansReducer,
    clients: clientsReducer,
    invoices: invoiceReducer,
    schedule: scheduleReducer,
  })
);

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };

export * from "./Thunks/appointmentsThunk";
export * from "./Thunks/cliniciansThunk";
export * from "./Thunks/clientsThunk";
export * from "./Thunks/invoicesThunk";
export * from "./Thunks/scheduleThunk";
