import React, { useState, useEffect } from "react";

const WeekSelectorDropdown = ({ onSelectWeek }) => {
    const [selectedWeek, setSelectedWeek] = useState("");
    const [weeks, setWeeks] = useState([]);

    // Utility function to add days to a date
    const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    // Utility function to format date as YYYY-MM-DD
    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };

    // Generate weeks given a start and end date
    const generateWeeks = (startDate, endDate) => {
        let start = new Date(startDate);
        start.setDate(start.getDate() - start.getDay() + 1); // Ensure start date is a Monday
        let end = new Date(endDate);

        const generatedWeeks = [];
        let id = 1;

        while (start < end) {
            const weekStart = new Date(start);
            const weekEnd = addDays(start, 6); // Sunday of the same week

            generatedWeeks.push({
                id: `week-${id}`,
                title: `Week ${id}: ${formatDate(weekStart)} - ${formatDate(weekEnd)}`,
                startDate: weekStart,
                endDate: weekEnd,
            });

            start = addDays(start, 7); // Move to the next Monday
            id += 1;
        }

        return generatedWeeks;
    };

    useEffect(() => {
        // Example: Generate weeks for a specific range
        const generatedWeeks = generateWeeks(new Date("2023-01-01"), new Date("2023-12-31"));
        setWeeks(generatedWeeks);
    }, []);

    const handleWeekChange = (event) => {
        const selectedValue = event.target.value;
        const selectedWeek = weeks.find((week) => week.id === selectedValue);
        setSelectedWeek(selectedWeek);
        onSelectWeek(selectedWeek);
    };

    return (
        <div className="my-4">
            <label htmlFor="weekSelector" className="mr-2 font-semibold">
                Select Week:
            </label>
            <select
                id="weekSelector"
                value={selectedWeek ? selectedWeek.id : ""}
                onChange={handleWeekChange}
                className="border border-gray-300 rounded-md p-1 h-12"
            >
                <option value="">Select a Week</option>
                {weeks.map((week) => (
                    <option key={week.id} value={week.id}>
                        {week.title}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default WeekSelectorDropdown;
