function TimeGrid() {
    return (
        <div className="col-start-1 col-end-2 row-start-1 grid h-full divide-y divide-gray-100">
            {/* Array of 24 hours to generate grid rows and labels */}
            {Array.from({ length: 24 }, (_, index) => (
                <div key={index}>
                    <div className="sticky top-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                        {index === 0 ? "12AM" : index < 12 ? `${index}AM` : index === 12 ? "12PM" : `${index - 12}PM`}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TimeGrid;
