import React from "react";
import ClientAvatar from "../../Components/ClientAvatar";
import RandomNatureImage from "../../Components/RandomImage";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";

const ClientProfileHeader = ({ selectedClient }) => {
    return (
        <>
            {/* Profile header */}
            <div>
                <div>
                    <div>
                        <img
                            className="h-32 w-full object-cover lg:h-48"
                            src=""
                            alt=""
                        />
                    </div>
                </div>
                <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                        <div className="flex">
                            <ClientAvatar
                                name={selectedClient.client_name}
                                size={10}
                            />
                        </div>
                        <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                            <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                                <h1 className="truncate text-2xl font-bold text-gray-900">
                                    {selectedClient.client_name}
                                </h1>
                            </div>
                            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                                <button
                                    type="button"
                                    className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    <EnvelopeIcon
                                        className="-ml-0.5 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                    Message
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    <PhoneIcon
                                        className="-ml-0.5 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                    Call
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                        <h1 className="truncate text-2xl font-bold text-gray-900">
                            {selectedClient.client_name}
                        </h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientProfileHeader;
