import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCliniciansList } from "../../Store/Thunks/cliniciansThunk";
import {
    updateClientClinician,
    updateClientSupervisor,
} from "../../Store/Thunks/clientsThunk";

const ClientProfile = ({ profile }) => {
    console.log(profile);
    const dispatch = useDispatch();
    const clinicians = useSelector((state) => state.clinicians.cliniciansList); // Adjust path according to your store structure
    const [isEditingClinician, setIsEditingClinician] = useState(false);
    const [isEditingSupervisor, setIsEditingSupervisor] = useState(false);
    const [selectedClinician, setSelectedClinician] = useState(
        profile.Clinician?.Clinician
    );
    const [selectedSupervisor, setSelectedSupervisor] = useState(
        profile.Supervisor?.Supervisor
    );

    useEffect(() => {
        dispatch(fetchCliniciansList);
    }, [dispatch]);

    const birthDate = new Date(profile.birthDate);
    const birthDateStr = birthDate.toDateString();
    const fields = {
        Birthday: birthDateStr,
        HomePhone: profile.phone?.home,
        MobilePhone: profile.phone?.mobile,
        WorkPhone: profile.phone?.work,
        Email: profile.email,
        "Referring Doctor": profile.health_care_provider,
    };

    const handleClinicianChange = (e) => {
        console.log("Set Clinician Name");
        setSelectedClinician(e.target.value.name);
        console.log("Call updateClientClinician");
        dispatch(
            updateClientClinician({
                client: profile,
                clinician: e.target.value,
            })
        );
    };

    const handleSupervisorChange = (e) => {
        console.log("Set Supervisor Name");
        setSelectedClinician(e.target.value.name);
        console.log("Call updateClientSupervisor");
        dispatch(
            updateClientSupervisor({
                client: profile,
                clinician: e.target.value,
            })
        );
    };

    return (
        <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                        Clinician
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {isEditingClinician ? (
                            <select
                                value={selectedClinician}
                                onChange={handleClinicianChange}
                                onBlur={() => setIsEditingClinician(false)}
                            >
                                <option value="">Select</option>
                                {clinicians.map((clinician) => (
                                    <option
                                        key={clinician.id}
                                        value={clinician.id}
                                    >
                                        {clinician.name}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <span>
                                {selectedClinician}{" "}
                                <button
                                    onClick={() => setIsEditingClinician(true)}
                                >
                                    🖉
                                </button>
                            </span>
                        )}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                        Supervisor
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {isEditingSupervisor ? (
                            <select
                                value={selectedSupervisor}
                                onChange={handleSupervisorChange}
                                onBlur={() => setIsEditingSupervisor(false)}
                            >
                                <option value="">Select</option>
                                {clinicians.map((supervisor) => (
                                    <option
                                        key={supervisor.id}
                                        value={supervisor.id}
                                    >
                                        {supervisor.name}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <span>
                                {selectedSupervisor}{" "}
                                <button
                                    onClick={() => setIsEditingSupervisor(true)}
                                >
                                    🖉
                                </button>
                            </span>
                        )}
                    </dd>
                </div>
                {Object.keys(fields).map((field) => {
                    // Check if the field value is not blank (undefined or empty string)
                    if (fields[field] !== undefined && fields[field] !== "") {
                        return (
                            <div key={field} className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                    {field}
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {fields[field]}
                                </dd>
                            </div>
                        );
                    }
                    return null; // Skip rendering if the field is blank
                })}
            </dl>
        </div>
    );
};

export default ClientProfile;
