import React, { useState } from "react";
import ClientDetails from "../Clients/ClientDetails";
import StripedTable from "../../Components/StripedTable";

// Function to format a Date object to a string in "en-GB" format
const formatDate = (date) => {
    return date.toLocaleDateString("en-GB");
};

const ClientList = ({ appointments, clinicianName }) => {
    const [selectedClient, setSelectedClient] = useState(null);

    // Calculate client summary data
    const clientSummaries = Object.values(
        appointments.reduce((summary, appointment) => {
            // Check if the appointment matches the clinician's name
            if (appointment.Provider === clinicianName) {
                const client = appointment.Client;
                const dateParts = appointment.Date.split("/").map(Number);
                const today = new Date();
                let totalAppointments = 0;
                let pastAppointments = 0;
                let futureAppointments = 0;
                const appointmentDate = new Date(
                    dateParts[2],
                    dateParts[1] - 1,
                    dateParts[0]
                );

                if (!summary[client]) {
                    if (appointmentDate < today) {
                        totalAppointments += 1;
                        pastAppointments += 1;
                        futureAppointments += 0;
                    } else {
                        totalAppointments += 1;
                        pastAppointments += 0;
                        futureAppointments += 1;
                    }
                    summary[client] = {
                        clientId: appointment["Client ID"],
                        clientName: client,
                        lastSeen: appointmentDate,
                        totalAppointments: totalAppointments,
                        futureAppointments: futureAppointments,
                        pastAppointments: pastAppointments,
                    };
                } else {
                    if (appointmentDate > summary[client].lastSeen) {
                        summary[client].lastSeen = appointmentDate;
                    }
                    if (appointmentDate < today) {
                        summary[client].pastAppointments += 1;
                    } else {
                        summary[client].futureAppointments += 1;
                    }
                    summary[client].totalAppointments += 1;
                }
            }
            return summary;
        }, {})
    );

    // Sort the client summaries by last seen date (most recent first)
    clientSummaries.sort((a, b) => b.lastSeen - a.lastSeen);

    const handleClientClick = (client) => {
        setSelectedClient(client);
    };

    const handleBackClick = () => {
        setSelectedClient(null); // Clear the selected clinician
    };

    const clientSummariesWithFormattedDates = clientSummaries.map((client) => ({
        ...client,
        lastSeenFormatted: formatDate(client.lastSeen), // Format the lastSeen date
        appointmentCounts: `${client.totalAppointments} - ${client.pastAppointments} - ${client.futureAppointments}`,
    }));

    return (
        <div>
            {selectedClient === null ? (
                <>
                    <StripedTable
                        title="Client List"
                        description="A table of client information"
                        headings={[
                            "Client Name",
                            "Last Booked Appointment",
                            "Total - Past - Future Appointments",
                        ]}
                        data={clientSummariesWithFormattedDates}
                        rowClickable={true}
                        onRowClick={handleClientClick}
                        showButton={false}
                        dataMapping={{
                            "Client Name": "clientName",
                            "Last Booked Appointment": "lastSeenFormatted", // Use formatted date
                            "Total - Past - Future Appointments":
                                "appointmentCounts",
                        }}
                    />
                </>
            ) : (
                <ClientDetails
                    client={selectedClient}
                    onBackClick={handleBackClick}
                />
            )}
        </div>
    );
};

export default ClientList;
