import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
    "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
    // Add other headers as needed
  },
};

export const fetchCliniciansList = createAsyncThunk(
  "clinicians/fetchCliniciansList", 
  async (_, { getState }) => {

    const response = await axios.get(
      `https://portal.hearsay.ca/api/clinicians`,
      config
    );
    if (!response.stats === 200) {
      throw new Error(response.data.message || "Could not fetch clinicians");
    }
    
    return response.data;
  }
);
