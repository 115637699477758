import React, { useState } from "react";
import StripedTable from "../../Components/StripedTable";
import SoapNote from "../../Components/SoapNote";

const TherapyAppointments = (appointments) => {
    // State to manage the modal
    const [isSoapNoteModalOpen, setSoapNoteModalOpen] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [isViewMode, setViewMode] = useState(false);
    
    console.log(appointments);

    if (!Array.isArray(appointments.appointments)) {
        return <div>Loading appointments...</div>;
    }

    function convertDateToSortableFormat(dateString) {
        const [day, month, year] = dateString.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }

    const openSoapNoteModal = (item, viewMode) => {
        setSelectedAppointment(item);
        setViewMode(viewMode);
        setSoapNoteModalOpen(true);
    };

    const closeSoapNoteModal = () => {
        setSelectedAppointment(null);
        setViewMode(false);
        setSoapNoteModalOpen(false);
    };

    const handleAddSoapClick = (item) => {
        // Open the SOAP note modal in add mode
        openSoapNoteModal(item, false);
    };

    const handleViewSoapClick = (item) => {
        // Open the SOAP note modal in view mode
        openSoapNoteModal(item, true);
    };

    const sortedAppointments = appointments.appointments
        .slice()
        .sort((a, b) => {
            const dateA = convertDateToSortableFormat(a.Date);
            const dateB = convertDateToSortableFormat(b.Date);
            return dateB.localeCompare(dateA);
        });

    return (
        <div>
            <h2>Therapy Appointments</h2>
            <StripedTable
                title="My Clients Appointments"
                description="A list of all your clients' appointments"
                headings={[
                    "Client Id",
                    "Client Name",
                    "Appointment Date",
                    "Appointment Type",
                ]}
                data={sortedAppointments}
                showButton={true}
                buttonText=""
                dataMapping={{
                    "Client Id": "Client ID",
                    "Client Name": "Client",
                    "Appointment Date": "Date",
                    "Appointment Type": "Type",
                }}
                buttonRenderer={(item) => (
                    <div>
                        {item.hasSoap ? (
                            <button
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                onClick={() => handleViewSoapClick(item)}
                            >
                                View Soap
                            </button>
                        ) : (
                            <button
                                className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                onClick={() => handleAddSoapClick(item)}
                            >
                                Add Soap
                            </button>
                        )}
                    </div>
                )}
            />
            {isSoapNoteModalOpen && (
                <SoapNote
                    isOpen={isSoapNoteModalOpen}
                    onClose={closeSoapNoteModal}
                    isViewMode={isViewMode}
                    appointment={selectedAppointment}
                />
            )}
        </div>
    );
};

export default TherapyAppointments;
