// Helper function to check if a date falls within the current month to date.
const isMonthToDate = (date, asOfDate) => {
    const currentDate = asOfDate || new Date(); // Use asOfDate if provided, otherwise use the current date
    const currentMonthStart = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
    );
    const currentMonthEnd = asOfDate || new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
    );

    return date >= currentMonthStart && date <= currentMonthEnd;
};

export default isMonthToDate;

