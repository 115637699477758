import React from "react";
import classNames from "classnames";

const ClientTabs = ({ tabs, selectedTab, onTabClick }) => {
  const handleTabClick = (tab) => {
    onTabClick(tab);
  };

  return (
    <div className="mt-6 sm:mt-2 2xl:mt-5">
      <div className="border-b border-gray-200">
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={() => handleTabClick(tab)} // Handle tab click
                className={classNames(
                  "cursor-pointer", // Add cursor-pointer for hover effect
                  tab === selectedTab
                    ? "border-pink-500 text-gray-900"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                )}
                aria-current={tab === selectedTab ? "page" : undefined} // Check if the tab is the selected tab
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default ClientTabs;
