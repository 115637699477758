import React from "react";
import { appointmentTypes } from "../../Constants/appointmentTypes";
import IsLastWeek from "../../Util/IsLastWeek";
import isPreviousWeek from "../../Util/IsPreviousWeek";
import isCurrentMonth from "../../Util/IsCurrentMonth";
import isPreviousMonth from "../../Util/IsPreviousMonth";
import isCurrentYear from "../../Util/IsCurrentYear";

const convertDate = (date) => {
    const appointmentDateParts = date.split("/"); // Assuming date is in the 4th column formatted as "MM/DD/YY"
    const appointmentDate = new Date(
        appointmentDateParts[2], // Year
        appointmentDateParts[1] - 1, // Month (subtracting 1 for zero-based indexing)
        appointmentDateParts[0] // Day
    );
    return appointmentDate;
};

export const reduceAppointments = (appointments) => {
    const summary = appointments.reduce((summary, appointment) => {
        const appointmentType = appointment["Type"];
        let appointmentDate = convertDate(appointment["Date"]);
        const foundType = appointmentTypes.find(
            ({ type }) => type === appointmentType
        );

        if (foundType) {
            const category = foundType.appType;
            if (!summary[category]) {
                // Initialize if not present
                summary[category] = {
                    lastWeekCount: 0,
                    previousWeekCount: 0,
                    totalThisMonthCount: 0,
                    totalLastMonthCount: 0,
                    totalThisYearCount: 0,
                };
            }

            // Increment counters based on conditions
            if (IsLastWeek(appointmentDate)) {
                summary[category].lastWeekCount += 1;
            } else if (isPreviousWeek(appointmentDate)) {
                summary[category].previousWeekCount += 1;
            }

            if (isCurrentMonth(appointmentDate)) {
                summary[category].totalThisMonthCount += 1;
            } else if (isPreviousMonth(appointmentDate)) {
                summary[category].totalLastMonthCount += 1;
            }

            if (isCurrentYear(appointmentDate)) {
                summary[category].totalThisYearCount += 1;
            }
        } else {
            console.log(`Appointment type "${appointmentType}" not found.`);
        }

        return summary; // Fix: Return the accumulator for the next iteration
    }, {}); // Initial value for the summary object

    const clientData = {};
    const today = new Date();
    const previousSunday = new Date();
    previousSunday.setDate(today.getDate() - ((today.getDay() + 7) % 7)); // Calculate the date of the previous Sunday

    // Process appointments data
    appointments.forEach((appointment) => {
        const clientId = appointment["Client ID"];
        let appointmentDate = convertDate(appointment["Date"]);
        const appointmentType = appointment["Type"];
        const clientName = appointment["Client"];
        const foundType = appointmentTypes.find(
            (type) => type.type === appointmentType
        );
        if (!foundType || foundType.category !== "Speech Therapy") {
            return;
        }
        if (!clientData[clientId]) {
            clientData[clientId] = {
                clientId,
                clientName,
                lastAppointmentDate: appointmentDate,
                lastAppointmentDateString: appointmentDate.toDateString(),
                appointmentType,
                totalAppointments: 1,
            };
        } else if (appointmentDate > clientData[clientId].lastAppointmentDate) {
            clientData[clientId] = {
                ...clientData[clientId],
                lastAppointmentDate: appointmentDate,
                lastAppointmentDateString: appointmentDate.toDateString(),
                appointmentType,
            };
            clientData[clientId].totalAppointments++;
        }
    });

    // Convert clientData object to an array and sort by last appointment date (oldest to newest)
    const clientList = Object.values(clientData).sort(
        (a, b) => a.lastAppointmentDate - b.lastAppointmentDate
    );

    // Filter clients whose last appointment was between 60 and 67 days prior to the previous Sunday
    const filteredClients = clientList.filter((client) => {
        const daysSinceLastAppointment = Math.floor(
            (previousSunday.getTime() - client.lastAppointmentDate.getTime()) /
                (1000 * 60 * 60 * 24)
        );
        return daysSinceLastAppointment >= 60 && daysSinceLastAppointment <= 67;
    });

    // Count the number of active clients (clients with appointments in the last 59 days and category "Speech Therapy")
    const activeClients60DaysCount = clientList.filter(
        (client) =>
            appointmentTypes.find(
                (type) =>
                    type.category === "Speech Therapy" &&
                    type.type === client.appointmentType
            ) &&
            Math.floor(
                (today.setHours(0, 0, 0, 0) -
                    client.lastAppointmentDate.setHours(0, 0, 0, 0)) /
                    (1000 * 60 * 60 * 24)
            ) <= 59
    ).length;

    // Count the number of active clients within the last 30 days (with category "Speech Therapy")
    const activeClients30DaysCount = clientList.filter(
        (client) =>
            appointmentTypes.find(
                (type) =>
                    type.category === "Speech Therapy" &&
                    type.type === client.appointmentType
            ) &&
            Math.floor(
                (today.setHours(0, 0, 0, 0) -
                    client.lastAppointmentDate.setHours(0, 0, 0, 0)) /
                    (1000 * 60 * 60 * 24)
            ) <= 30
    ).length;

    // Assuming `clientList` and `appointmentTypes` are already defined
    let thirtyDayActiveLast365 = Array(365).fill(0);

    for (let i = 0; i < 365; i++) {
        // Calculate the day for the current iteration
        let dayIteration = new Date(today.getTime() - i * 1000 * 60 * 60 * 24);

        // Calculate the count of active clients for this day
        const activeClients30DaysCount = clientList.filter(
            (client) =>
                appointmentTypes.find(
                    (type) =>
                        type.category === "Speech Therapy" &&
                        type.type === client.appointmentType
                ) &&
                Math.floor(
                    (dayIteration.setHours(0, 0, 0, 0) -
                        new Date(client.lastAppointmentDate).setHours(
                            0,
                            0,
                            0,
                            0
                        )) /
                        (1000 * 60 * 60 * 24)
                ) <= 30
        ).length;

        // Store the count in the array
        thirtyDayActiveLast365[i] = activeClients30DaysCount;
    }

    return { summary, activeClients30DaysCount, activeClients60DaysCount, thirtyDayActiveLast365 };
};
