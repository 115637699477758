import React, { useEffect, useState } from "react";
import axios from "axios";
import ClientList from "../Tables/ClientList";

const config = {
    headers: {
      "Content-Type": "application/json",
      "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
      "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
      // Add other headers as needed
    },
  };

const ClientDropoff = () => {
    const [appointmentsData, setAppointmentsData] = useState([]);
    const [categorySummary, setCategorySummary] = useState({});

    useEffect(() => {
        // Use an async function inside useEffect to fetch data
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    "https://portal.hearsay.ca/api/appointments",
                    config
                );
                const appointmentsArray = response.data.appointments; // Access the "appointments" key
                console.log(appointmentsArray);
                if (Array.isArray(appointmentsArray)) {
                    setAppointmentsData(appointmentsArray);
                } else {
                    console.error(
                        "Data from the server is not an array:",
                        appointmentsArray
                    );
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(); // Call the async function to fetch data when the component mounts
    }, []);

    useEffect(() => {
        // Process appointment data and calculate category summary
        const newCategorySummary = {}; // Initialize a new category summary object

        appointmentsData.forEach((appointment) => {
            const category = appointment.category;
            const duration = parseInt(appointment["Actual duration"], 10) || 0; // Parse duration or default to 0

            // Update or initialize the category summary
            if (newCategorySummary[category]) {
                newCategorySummary[category] += duration;
            } else {
                newCategorySummary[category] = duration;
            }
        });

        setCategorySummary(newCategorySummary); // Update the category summary state
    }, [appointmentsData]);

    return (
        <div className="container">
            <p className="mt-4">
                Number of Appointments: {appointmentsData.length}
            </p>
            <ClientList appointments={appointmentsData} />
        </div>
    );
};

export default ClientDropoff;
