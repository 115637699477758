import React, { useEffect } from "react";
import { appointmentTypes } from "../Constants/appointmentTypes";
import IsLastWeek from "../Util/IsLastWeek";
import isPreviousWeek from "../Util/IsPreviousWeek";
import isCurrentMonth from "../Util/IsCurrentMonth";
import isPreviousMonth from "../Util/IsPreviousMonth";
import isMonthToDate from "../Util/isMonthToDate";
import isCurrentYear from "../Util/IsCurrentYear";
import isYearToDate from "../Util/isYearToDate";

const ClientSummaryTable = ({ appointments, asOfDate }) => {
    useEffect(() => {}, [asOfDate]);

    // Calculate the summary data by clinician and time frames
    const summaryData = appointments.reduce((summary, appointment) => {
        const clinician = appointment["Provider"]; // Assuming clinician name is in the 2nd column
        if (appointment["Date"] === null) {
            console.log(appointment);
        }
        const appointmentDateParts = appointment["Date"].split("/"); // Assuming date is in the 4th column formatted as "MM/DD/YY"
        const appointmentDate = new Date(
            appointmentDateParts[2],
            appointmentDateParts[1] - 1,
            appointmentDateParts[0]
        );
        appointmentDate.setDate(appointmentDate.getDate() + 1);
        const duration =
            parseFloat(appointment["Length"].replace(" min", "").trim()) / 60; // Convert duration from minutes to hours

        if (summary[clinician]) {
            summary[clinician].totalDuration += duration;

            if (IsLastWeek(appointmentDate, asOfDate)) {
                summary[clinician].lastWeek += duration;
                if(clinician === "Guddusi, Hiba") {
                    console.log(appointment);
                    // console.log(summary[clinician]);
                }
            } else if (isPreviousWeek(appointmentDate, asOfDate)) {
                summary[clinician].previousWeek += duration;
            }

            if (isMonthToDate(appointmentDate, asOfDate)) {
                summary[clinician].totalMonthToDate += duration;
            }

            if (isCurrentMonth(appointmentDate, asOfDate)) {
                summary[clinician].totalThisMonth += duration;
            } else if (isPreviousMonth(appointmentDate, asOfDate)) {
                summary[clinician].totalLastMonth += duration;
            }

            if (isCurrentYear(appointmentDate, asOfDate)) {
                summary[clinician].totalThisYear += duration;
            }

            if (isYearToDate(appointmentDate, asOfDate)) {
                summary[clinician].totalYearToDate += duration;
            }
        } else {
            summary[clinician] = {
                totalDuration: duration,
                lastWeek: IsLastWeek(appointmentDate, asOfDate) ? duration : 0,
                previousWeek: isPreviousWeek(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                totalThisMonth: isCurrentMonth(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                totalMonthToDate: isMonthToDate(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                totalLastMonth: isPreviousMonth(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                totalThisYear: isCurrentYear(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                totalYearToDate: isYearToDate(appointmentDate, asOfDate)
                    ? duration
                    : 0,
            };
        }

        return summary;
    }, {});

    const categorySummaryByMonth = appointments.reduce(
        (summary, appointment) => {
            const appointmentType = appointment["Type"];
            const appointmentDateParts = appointment["Date"].split("/"); // Assuming date is in the 4th column formatted as "MM/DD/YY"
            const appointmentDate = new Date(
                appointmentDateParts[2],
                appointmentDateParts[1] - 1,
                appointmentDateParts[0]
            );
            appointmentDate.setDate(appointmentDate.getDate() + 1);
            const foundType = appointmentTypes.find(
                ({ type }) => type === appointmentType
            );
            const duration =
                parseFloat(appointment["Length"].replace(" min", "").trim()) /
                60;

            if (foundType) {
                const category = foundType.category;
                const month = appointmentDate.getMonth(); // Get the month (0-11) of the appointment date

                if (!summary[category]) {
                    summary[category] = Array(12).fill({ count: 0, hours: 0 }); // Initialize the summary array for the category
                }

                summary[category][month].count += 1; // Increment the count for the month and category
                summary[category][month].hours += duration; // Add the duration for the month and category
            } else {
                console.log(`Appointment type "${appointmentType}" not found.`);
            }

            return summary;
        },
        {}
    );

    // Calculate the summary data by category
    const categorySummary = appointments.reduce((summary, appointment) => {
        const appointmentType = appointment["Type"];
        const appointmentDateParts = appointment["Date"].split("/"); // Assuming date is in the 4th column formatted as "MM/DD/YY"
        const appointmentDate = new Date(
            appointmentDateParts[2],
            appointmentDateParts[1] - 1,
            appointmentDateParts[0]
        );
        appointmentDate.setDate(appointmentDate.getDate() + 1);
        const foundType = appointmentTypes.find(
            ({ type }) => type === appointmentType
        );
        const duration =
            parseFloat(appointment["Length"].replace(" min", "").trim()) / 60; // Convert duration from minutes to hours

        if (foundType) {
            const category = foundType.category;
            if (summary[category]) {
                summary[category].totalDuration += duration;

                if (IsLastWeek(appointmentDate, asOfDate)) {
                    summary[category].lastWeek += duration;
                    summary[category].lastWeekCount += 1;
                } else if (isPreviousWeek(appointmentDate, asOfDate)) {
                    summary[category].previousWeek += duration;
                    summary[category].previousWeekCount += 1;
                }

                if (isCurrentMonth(appointmentDate, asOfDate)) {
                    summary[category].totalThisMonth += duration;
                    summary[category].totalThisMonthCount += 1;
                } else if (isPreviousMonth(appointmentDate, asOfDate)) {
                    summary[category].totalLastMonth += duration;
                    summary[category].totalLastMonthCount += 1;
                }

                if (isMonthToDate(appointmentDate, asOfDate)) {
                    summary[category].totalMonthToDate += duration;
                    summary[category].totalMonthToDateCount += 1;
                }

                if (isCurrentYear(appointmentDate, asOfDate)) {
                    summary[category].totalThisYear += duration;
                    summary[category].totalThisYearCount += 1;
                }

                if (isYearToDate(appointmentDate, asOfDate)) {
                    summary[category].totalYearToDate += duration;
                    summary[category].totalYearToDateCount += 1;
                }
            } else {
                summary[category] = {
                    totalDuration: duration,
                    lastWeek: IsLastWeek(appointmentDate, asOfDate)
                        ? duration
                        : 0,
                    lastWeekCount: IsLastWeek(appointmentDate, asOfDate)
                        ? 1
                        : 0, // Initialize the count property
                    previousWeek: isPreviousWeek(appointmentDate, asOfDate)
                        ? duration
                        : 0,
                    previousWeekCount: isPreviousWeek(appointmentDate, asOfDate)
                        ? 1
                        : 0, // Initialize the count property
                    totalThisMonth: isCurrentMonth(appointmentDate, asOfDate)
                        ? duration
                        : 0,
                    totalThisMonthCount: isCurrentMonth(
                        appointmentDate,
                        asOfDate
                    )
                        ? 1
                        : 0, // Initialize the count property
                    totalMonthToDate: isMonthToDate(appointmentDate, asOfDate)
                        ? duration
                        : 0,
                    totalMonthToDateCount: isMonthToDate(
                        appointmentDate,
                        asOfDate
                    )
                        ? 1
                        : 0, // Initialize the count property
                    totalLastMonth: isPreviousMonth(appointmentDate, asOfDate)
                        ? duration
                        : 0,
                    totalLastMonthCount: isPreviousMonth(
                        appointmentDate,
                        asOfDate
                    )
                        ? 1
                        : 0, // Initialize the count property
                    totalThisYear: isCurrentYear(appointmentDate, asOfDate)
                        ? duration
                        : 0,
                    totalThisYearCount: isCurrentYear(appointmentDate, asOfDate)
                        ? 1
                        : 0, // Initialize the count property
                    totalYearToDateCount: isYearToDate(
                        appointmentDate,
                        asOfDate
                    )
                        ? 1
                        : 0, // Initialize the count property
                    totalYearToDate: isCurrentYear(appointmentDate, asOfDate)
                        ? duration
                        : 0,
                };
            }
        } else {
            console.log(`Appointment type "${appointmentType}" not found.`);
        }

        return summary;
    }, {});

    // Calculate the summary of total appointments and total hours for specific appointment types
    const appointmentSummary = appointments.reduce((summary, appointment) => {
        const appointmentType = appointment["Type"]; // Assuming appointment type is in the 3rd column
        const appointmentDateParts = appointment["Date"].split("/"); // Assuming date is in the 4th column formatted as "MM/DD/YYYY"
        const appointmentDate = new Date(
            appointmentDateParts[2],
            appointmentDateParts[1] - 1,
            appointmentDateParts[0]
        );
        appointmentDate.setDate(appointmentDate.getDate() + 1);
        const duration =
            parseFloat(appointment["Length"].replace(" min", "").trim()) / 60; // Convert duration from minutes to hours

        if (summary[appointmentType]) {
            summary[appointmentType].totalAppointments += 1;
            summary[appointmentType].totalHours += duration;

            if (IsLastWeek(appointmentDate, asOfDate)) {
                summary[appointmentType].lastWeek += duration;
                summary[appointmentType].lastWeekCount += 1;
            } else if (isPreviousWeek(appointmentDate, asOfDate)) {
                summary[appointmentType].previousWeek += duration;
                summary[appointmentType].previousWeekCount += 1;
            }

            if (isCurrentMonth(appointmentDate, asOfDate)) {
                summary[appointmentType].totalThisMonth += duration;
                summary[appointmentType].totalThisMonthCount += 1;
            } else if (isPreviousMonth(appointmentDate, asOfDate)) {
                summary[appointmentType].totalLastMonth += duration;
                summary[appointmentType].totalLastMonthCount += 1;
            }

            if (isMonthToDate(appointmentDate, asOfDate)) {
                summary[appointmentType].totalMonthToDate += duration;
                summary[appointmentType].totalMonthToDateCount += 1;
            }

            if (isCurrentYear(appointmentDate, asOfDate)) {
                summary[appointmentType].totalThisYear += duration;
                summary[appointmentType].totalThisYearCount += 1;
            }

            if (isYearToDate(appointmentDate, asOfDate)) {
                summary[appointmentType].totalYearToDate += duration;
                summary[appointmentType].totalYearToDateCount += 1;
            }
        } else {
            summary[appointmentType] = {
                totalAppointments: 1,
                totalHours: duration,
                lastWeek: IsLastWeek(appointmentDate, asOfDate) ? duration : 0,
                lastWeekCount: IsLastWeek(appointmentDate, asOfDate) ? 1 : 0,
                previousWeek: isPreviousWeek(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                previousWeekCount: isPreviousWeek(appointmentDate, asOfDate)
                    ? 1
                    : 0,
                totalThisMonth: isCurrentMonth(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                totalThisMonthCount: isCurrentMonth(appointmentDate, asOfDate)
                    ? 1
                    : 0,
                totalMonthToDate: isMonthToDate(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                totalMonthToDateCount: isMonthToDate(appointmentDate, asOfDate)
                    ? 1
                    : 0,
                totalLastMonth: isPreviousMonth(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                totalLastMonthCount: isPreviousMonth(appointmentDate, asOfDate)
                    ? 1
                    : 0,
                totalYearToDate: isCurrentYear(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                totalYearToDateCount: isCurrentYear(appointmentDate, asOfDate)
                    ? 1
                    : 0,
                totalThisYear: isCurrentYear(appointmentDate, asOfDate)
                    ? duration
                    : 0,
                totalThisYearCount: isCurrentYear(appointmentDate, asOfDate)
                    ? 1
                    : 0,
            };
        }

        return summary;
    }, {});

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Summary by Clinician
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A summary of appointments by clinician
                        </p>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                        >
                                            Clinician
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Last Week
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Previous Week
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This MTD
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This Month
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total Last Month
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This YTD
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This Year
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {Object.entries(summaryData).map(
                                        ([clinician, summary]) =>
                                            // Check if all summary values are zero before rendering the row
                                            !(
                                                summary.lastWeek === 0 &&
                                                summary.previousWeek === 0 &&
                                                summary.totalThisMonth === 0 &&
                                                summary.totalMonthToDate ===
                                                    0 &&
                                                summary.totalLastMonth === 0 &&
                                                summary.totalYearToDate === 0 &&
                                                summary.totalThisYear === 0
                                            ) && (
                                                <tr
                                                    key={clinician}
                                                    className="even:bg-gray-50"
                                                >
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                        {clinician}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {summary.lastWeek.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {summary.previousWeek.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {summary.totalMonthToDate.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {summary.totalThisMonth.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {summary.totalLastMonth.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {summary.totalYearToDate.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                        {summary.totalThisYear.toFixed(
                                                            2
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Summary By Category
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A summary of appointments by category
                        </p>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                        >
                                            Category
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Last Week
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Previous Week
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This MTD
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This Month
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total Last Month
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This YTD
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This Year
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {Object.entries(categorySummary).map(
                                        ([category, summary]) => (
                                            <tr
                                                key={category}
                                                className="even:bg-gray-50"
                                            >
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                    {category}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.lastWeek.toFixed(
                                                        2
                                                    )}{" "}
                                                    ({summary.lastWeekCount})
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.previousWeek.toFixed(
                                                        2
                                                    )}{" "}
                                                    ({summary.previousWeekCount}
                                                    )
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.totalMonthToDate.toFixed(
                                                        2
                                                    )}{" "}
                                                    (
                                                    {
                                                        summary.totalMonthToDateCount
                                                    }
                                                    )
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.totalThisMonth.toFixed(
                                                        2
                                                    )}{" "}
                                                    (
                                                    {
                                                        summary.totalThisMonthCount
                                                    }
                                                    )
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.totalLastMonth.toFixed(
                                                        2
                                                    )}{" "}
                                                    (
                                                    {
                                                        summary.totalLastMonthCount
                                                    }
                                                    )
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.totalYearToDate.toFixed(
                                                        2
                                                    )}{" "}
                                                    (
                                                    {
                                                        summary.totalYearToDateCount
                                                    }
                                                    )
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.totalThisYear.toFixed(
                                                        2
                                                    )}{" "}
                                                    (
                                                    {summary.totalThisYearCount}
                                                    )
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">
                            Summary By Appointment Type
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            A summary of appointments by type
                        </p>
                    </div>
                </div>
                <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                        >
                                            Appointment Type
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Last Week
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Previous Week
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This MTD
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This Month
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total Last Month
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This YTD
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Total This Year
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {Object.entries(appointmentSummary)
                                        .sort(
                                            ([, a], [, b]) =>
                                                b.lastWeek - a.lastWeek
                                        ) // Sort by Last Week in descending order
                                        .map(([appointment, summary]) => (
                                            <tr
                                                key={appointment}
                                                className="even:bg-gray-50"
                                            >
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                    {appointment}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.lastWeek.toFixed(
                                                        2
                                                    )}{" "}
                                                    ({summary.lastWeekCount})
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.previousWeek.toFixed(
                                                        2
                                                    )}{" "}
                                                    ({summary.previousWeekCount}
                                                    )
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.totalMonthToDate.toFixed(
                                                        2
                                                    )}{" "}
                                                    (
                                                    {
                                                        summary.totalMonthToDateCount
                                                    }
                                                    )
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.totalThisMonth.toFixed(
                                                        2
                                                    )}{" "}
                                                    (
                                                    {
                                                        summary.totalThisMonthCount
                                                    }
                                                    )
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.totalLastMonth.toFixed(
                                                        2
                                                    )}{" "}
                                                    (
                                                    {
                                                        summary.totalLastMonthCount
                                                    }
                                                    )
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.totalYearToDate.toFixed(
                                                        2
                                                    )}{" "}
                                                    (
                                                    {
                                                        summary.totalYearToDateCount
                                                    }
                                                    )
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {summary.totalThisYear.toFixed(
                                                        2
                                                    )}{" "}
                                                    (
                                                    {summary.totalThisYearCount}
                                                    )
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientSummaryTable;
