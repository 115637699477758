  // Helper function to check if a date falls within the current year
  const isCurrentYear = (date, asOfDate) => {
    const currentDate = asOfDate || new Date(); // Use asOfDate if provided, otherwise use the current date
    const currentYearStart = new Date(currentDate.getFullYear(), 0, 1);
    const currentYearEnd = new Date(currentDate.getFullYear(), 11, 31);

    return date >= currentYearStart && date <= currentYearEnd;
};

export default isCurrentYear;
