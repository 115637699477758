import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useDispatch } from "react-redux";
import { saveSoapNote } from "../Store/store";

export default function SoapNote({ isOpen, onClose, isViewMode, appointment }) {
    const dispatch = useDispatch();
    const cancelButtonRef = useRef(null);
    console.log(appointment);
    // Define state variables for SOAP note fields
    const [sField, setSField] = useState("");
    const [oField, setOField] = useState("");
    const [aField, setAField] = useState("");
    const [pField, setPField] = useState("");
    const [clientName, setClientName] = useState("");
    const [clinicianName, setClinicianName] = useState("");
    const [dateOfAppointment, setDateOfAppointment] = useState("");
    const [appointmentType, setAppointmentType] = useState("");

    useEffect(() => {
        // Populate the state with data from the appointment when it changes
        setClientName(appointment.Client || "");
        setClinicianName(appointment.Provider || "");
        setDateOfAppointment(appointment.Date || "");
        setAppointmentType(appointment.Type || "");
    }, [appointment]);

    const handleSaveClick = async () => {
        // Handle saving SOAP note fields (sField, oField, aField, pField)
        const soapNote = {
            soapNote: {
                S: sField,
                O: oField,
                A: aField,
                P: pField,
                createdAt: new Date(),
                createdBy: "User123",
            },
        };

        // You can perform further actions with the SOAP note data here
        console.log("SOAP Note Data:", soapNote);
        console.log("Appointment " + appointment);

        const result = await dispatch(saveSoapNote([appointment, soapNote]));
        console.log("result " + JSON.stringify(result));
        // onClose(); // Close the modal after saving
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                initialFocus={cancelButtonRef}
                onClose={onClose}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon
                                            className="h-6 w-6 text-green-600"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            {isViewMode
                                                ? "View SOAP Note"
                                                : "Edit SOAP Note"}
                                        </Dialog.Title>
                                        <div className="mb-4">
                                            <input
                                                type="text"
                                                className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                placeholder="Client Name"
                                                value={clientName}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="text"
                                                className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                placeholder="Clinician Name"
                                                value={clinicianName}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="text"
                                                className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                placeholder="Date of Appointment"
                                                value={dateOfAppointment}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="text"
                                                className="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                placeholder="Appointment Type"
                                                value={appointmentType}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="mt-2">
                                            <textarea
                                                className="mt-2 form-textarea block w-full rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                rows="4"
                                                placeholder="S: Subjective"
                                                value={sField}
                                                readOnly={isViewMode}
                                                onChange={(e) =>
                                                    setSField(e.target.value)
                                                }
                                            />
                                            <textarea
                                                className="mt-2 form-textarea block w-full rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                rows="4"
                                                placeholder="O: Objective"
                                                value={oField}
                                                readOnly={isViewMode}
                                                onChange={(e) =>
                                                    setOField(e.target.value)
                                                }
                                            />
                                            <textarea
                                                className="mt-2 form-textarea block w-full rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                rows="4"
                                                placeholder="A: Assessment"
                                                value={aField}
                                                readOnly={isViewMode}
                                                onChange={(e) =>
                                                    setAField(e.target.value)
                                                }
                                            />
                                            <textarea
                                                className="mt-2 form-textarea block w-full rounded-md shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                rows="4"
                                                placeholder="P: Plan"
                                                value={pField}
                                                readOnly={isViewMode}
                                                onChange={(e) =>
                                                    setPField(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className={`inline-flex w-full justify-center rounded-md ${
                                            isViewMode
                                                ? "bg-indigo-600"
                                                : "bg-red-600"
                                        } px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                                            isViewMode
                                                ? "focus-visible:outline-indigo-600"
                                                : "focus-visible:outline-red-600"
                                        } sm:col-start-2`}
                                        onClick={handleSaveClick}
                                    >
                                        {isViewMode ? "Close" : "Save"}
                                    </button>
                                    {!isViewMode && (
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={onClose}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    )}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
