import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const ClientDetails = ({ client, onBackButtonClick }) => {
    // Access appointments from the Redux store
    const [appointments, setAppointments] = useState([]);
    const [activeTab, setActiveTab] = useState("appointments");

    function convertDateToSortableFormat(dateString) {
        const [day, month, year] = dateString.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }

    useEffect(() => {
        // Define an async function to fetch the data
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-token": process.env.REACT_APP_HEARSAY_TOKEN,
                        "x-api-secret": process.env.REACT_APP_HEARSAY_SECRET,
                        // Add other headers as needed
                    },
                };
                const response = await axios.get(
                    `https://portal.hearsay.ca/api/appointments/clients/${client.client_id}`, // Use the clientId in the URL
                    config
                );

                if (response.status !== 200) {
                    throw new Error(
                        response.data.message || "Could not fetch appointments"
                    );
                }

                const data = response.data;

                data.sort((a, b) => {
                    const dateA = convertDateToSortableFormat(a.Date);
                    const dateB = convertDateToSortableFormat(b.Date);
                    return dateB.localeCompare(dateA);
                });

                // Now you can handle the data, for example, set it in state
                // For this example, let's assume you have a setAppointments function
                setAppointments(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        // Call the fetchData function immediately
        fetchData();
    }, [client]);

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className="container">
            <div className="header">
                <div className="d-flex align-items-center">
                    <Link
                        to="/clients"
                        className="btn btn-secondary me-3"
                        onClick={onBackButtonClick}
                    >
                        &lt; Back
                    </Link>
                    <h2>Client Details</h2>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <h3 className="card-title">{client.client_name}</h3>
                    <p className="card-text">Client ID: {client.client_id}</p>
                    <p className="card-text">
                        Clinician: {client.clinician_name}
                    </p>
                </div>
            </div>
            <ul className="nav nav-tabs mt-4">
                <li className="nav-item">
                    <button
                        onClick={() => handleTabChange("appointments")}
                        className={`nav-link ${
                            activeTab === "appointments" ? "active" : ""
                        }`}
                    >
                        Appointments
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        onClick={() => handleTabChange("tab2")}
                        className={`nav-link ${
                            activeTab === "tab2" ? "active" : ""
                        }`}
                    >
                        Tab 2
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        onClick={() => handleTabChange("tab3")}
                        className={`nav-link ${
                            activeTab === "tab3" ? "active" : ""
                        }`}
                    >
                        Tab 3
                    </button>
                </li>
            </ul>
            <div className="tab-content mt-3">
                {activeTab === "appointments" && (
                    <div>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Client</th>
                                    <th>Type</th>
                                    <th>Scheduled Duration</th>
                                    <th>Clinician</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(appointments) &&
                                    appointments.map((appointment) => (
                                        <tr key={appointment._id}>
                                            <td>{appointment.Date}</td>
                                            <td>{appointment.Client}</td>
                                            <td>{appointment.Type}</td>
                                            <td>
                                                {
                                                    appointment[
                                                        "Scheduled duration"
                                                    ]
                                                }
                                            </td>
                                            <td>{appointment.Provider}</td>
                                            <td>
                                                {appointment.hasSoap ? (
                                                    <button className="btn btn-success">
                                                        View Soap
                                                    </button>
                                                ) : (
                                                    <button className="btn btn-danger">
                                                        Add Soap
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {activeTab === "tab2" && <div>{/* Content for Tab 2 */}</div>}
                {activeTab === "tab3" && <div>{/* Content for Tab 3 */}</div>}
            </div>
        </div>
    );
};

export default ClientDetails;
