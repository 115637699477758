import { Fragment, useEffect, useRef, useState } from "react";
import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import CurrentDateDisplay from "./components/currentDateDisplay";
import DateNavigator from "./components/dateNavigator";
import ClinicianSchedule from "./components/clinicianSchedule";
import TimeGrid from "./components/timeGrid";

const days = [
    { date: "2021-12-27" },
    { date: "2021-12-28" },
    { date: "2021-12-29" },
    { date: "2021-12-30" },
    { date: "2021-12-31" },
    { date: "2022-01-01", isCurrentMonth: true },
    { date: "2022-01-02", isCurrentMonth: true },
    { date: "2022-01-03", isCurrentMonth: true },
    { date: "2022-01-04", isCurrentMonth: true },
    { date: "2022-01-05", isCurrentMonth: true },
    { date: "2022-01-06", isCurrentMonth: true },
    { date: "2022-01-07", isCurrentMonth: true },
    { date: "2022-01-08", isCurrentMonth: true },
    { date: "2022-01-09", isCurrentMonth: true },
    { date: "2022-01-10", isCurrentMonth: true },
    { date: "2022-01-11", isCurrentMonth: true },
    { date: "2022-01-12", isCurrentMonth: true },
    { date: "2022-01-13", isCurrentMonth: true },
    { date: "2022-01-14", isCurrentMonth: true },
    { date: "2022-01-15", isCurrentMonth: true },
    { date: "2022-01-16", isCurrentMonth: true },
    { date: "2022-01-17", isCurrentMonth: true },
    { date: "2022-01-18", isCurrentMonth: true },
    { date: "2022-01-19", isCurrentMonth: true },
    { date: "2022-01-20", isCurrentMonth: true, isToday: true },
    { date: "2022-01-21", isCurrentMonth: true },
    { date: "2022-01-22", isCurrentMonth: true, isSelected: true },
    { date: "2022-01-23", isCurrentMonth: true },
    { date: "2022-01-24", isCurrentMonth: true },
    { date: "2022-01-25", isCurrentMonth: true },
    { date: "2022-01-26", isCurrentMonth: true },
    { date: "2022-01-27", isCurrentMonth: true },
    { date: "2022-01-28", isCurrentMonth: true },
    { date: "2022-01-29", isCurrentMonth: true },
    { date: "2022-01-30", isCurrentMonth: true },
    { date: "2022-01-31", isCurrentMonth: true },
    { date: "2022-02-01" },
    { date: "2022-02-02" },
    { date: "2022-02-03" },
    { date: "2022-02-04" },
    { date: "2022-02-05" },
    { date: "2022-02-06" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function CalTest() {
    const container = useRef(null);
    const containerNav = useRef(null);
    const containerOffset = useRef(null);

    const [currentDate, setCurrentDate] = useState(new Date());

    const onPrevDay = () => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            newDate.setDate(prevDate.getDate() - 1);
            return newDate;
        });
    };

    const onToday = () => {
        setCurrentDate(new Date());
    };

    const onNextDay = () => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            newDate.setDate(prevDate.getDate() + 1);
            return newDate;
        });
    };

    // useEffect(() => {
    //     // Set the container scroll position based on the current time.
    //     const currentMinute = new Date().getHours() * 60;
    //     container.current.scrollTop =
    //         ((container.current.scrollHeight -
    //             containerOffset.current.offsetHeight) *
    //             currentMinute) /
    //         1440;
    // }, []);

    return (
        <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:p-6">
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="flex h-full flex-col">
                        <header className="sticky top-0 z-10 flex w-full flex-none items-center justify-between border-b border-gray-200 bg-white px-6 py-4">
                            <CurrentDateDisplay currentDate={currentDate} />
                            <DateNavigator onPrevDay={onPrevDay} onToday={onToday} onNextDay={onNextDay} />
                        </header>
                        <div className="isolate flex flex-auto overflow-hidden bg-white">
                            <div className="flex flex-auto flex-col overflow-auto">
                                <div className="flex w-full flex-auto">
                                    <div className="w-14 flex-none bg-white ring-1 ring-gray-100" />
                                    <div className="flex flex-auto grid-cols-1 grid-rows-1">
                                        <TimeGrid />
                                        <div className="flex flex-1 overflow-x-auto">
                                            <ClinicianSchedule />
                                            <ClinicianSchedule />
                                            <ClinicianSchedule />
                                            <ClinicianSchedule />
                                            <ClinicianSchedule />
                                            <ClinicianSchedule />
                                            <ClinicianSchedule />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
